import React from 'react'
import { formatTZLabel } from './helper'
import JsonDSTZones from './timezonesDST'
import moment from 'moment-timezone'
import Tooltip from '../Tooltip/Tooltip'

const DSTTimeZoneList = () => {
  let tzs = [];
  let momentDate: any = moment();
  let newLabel: any = '';
  let singleTZDiffOffset, singleTZHourOffset, singleTZMinOffset, offsetVal;
  for (var _i = 0; _i < JsonDSTZones.length; _i++) {
    offsetVal = '';
    JsonDSTZones[_i].offset = momentDate.tz(JsonDSTZones[_i].value).format("Z");
    newLabel = formatTZLabel(JsonDSTZones[_i].label, JsonDSTZones[_i].value, JsonDSTZones[_i].offset);

    singleTZDiffOffset = newLabel.charAt(5);
    singleTZHourOffset = parseInt(newLabel.substring(6, newLabel.indexOf(':')));
    singleTZMinOffset = parseInt(newLabel.substr(newLabel.indexOf(':') + 1, 2));
    offsetVal = parseInt(singleTZDiffOffset + '' + (singleTZHourOffset * 60 + singleTZMinOffset));


    tzs.push({
      label: <Tooltip position="bottom" content={newLabel}>{newLabel}</Tooltip>,
      value: JsonDSTZones[_i].value,
      alias: JsonDSTZones[_i].alias || [],
      offsetValue: offsetVal,
      offsetString: JsonDSTZones[_i].offset.replace(':', ''),
      searchKey: newLabel
    });
  }
  return tzs;
};
export default DSTTimeZoneList