import addMonths from 'date-fns/addMonths'
import subMonths from 'date-fns/subMonths'
import differenceInCalendarMonths from 'date-fns/differenceInCalendarMonths'

/**
 * Checks if next month is disabled
 * @param {Date} date - The current date
 * @param {Date} maxMonth- max allowed month
 * @returns {boolean}
 */
const isNextMonthDisabled = (date: Date, maxMonth: Date) => {
  const nextMonth = addMonths(date, 1)
  return differenceInCalendarMonths(nextMonth, maxMonth) > 0
}

/**
 * Checks if prev month is disabled
 * @param {Date} date - The current date
 * @param {Date} minMonth- max allowed month
 * @returns {boolean}
 */
const isPrevMonthDisabled = (date: Date, minMonth: Date) => {
  const prevMonth = subMonths(date, 1)
  return differenceInCalendarMonths(minMonth, prevMonth) > 0
}

export { isNextMonthDisabled, isPrevMonthDisabled }
