import React, {
  Component,
} from 'react'
import { SketchPicker } from 'react-color';
import Button from '../Button/Button'
import ButtonGroup from '../Button/ButtonGroup/ButtonGroup'
import './ColorPicker.css'

const defaultProps = {
  testId: 'cs-color-picker',
  value: '#CF5CC5'
};

export type ColorPickerProps = {
  onChange?: Function;
  value: string;
  testId?: string,
  openColorPicker?: boolean,
  onClose?: Function,
}

class ColorPicker extends Component<ColorPickerProps> {
  public static propTypes: any = {}
  static defaultProps = defaultProps;

  state = {
    displayColorPicker: this.props.openColorPicker || false,
    color: this.props.value,
    tempColor: this.props.value
  };

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false })
    if (this.props.onClose) {
      this.props.onClose()
    }
  };

  handleChange = (color) => {
    this.setState({
      tempColor: color.hex
    })

  };

  handleChoose = () => {
    this.setState({
      color: this.state.tempColor,
      displayColorPicker: false
    })
    if (this.props.onChange) {
      this.props.onChange(this.state.tempColor)
    }
  }

  presetColors = [
    { color: '#FDBAB1', title: 'Pink' },
    { color: '#FA6E33', title: 'Orange' },
    { color: '#FFD600', title: 'Yellow' },
    { color: '#52C0FF', title: 'Blue' },
    { color: '#01977C', title: 'Green' },
    { color: '#6C5CE7', title: 'Purple' },
    { color: '#002C53', title: 'Ferrario' },

  ]

  colorObj = {
    "#fdbab1": 'Pink',
    "#fa6e33": 'Orange',
    "#ffd600": "Yellow",
    "#52c0ff": "Blue",
    "#01977c": "Green",
    "#6c5ce7": "Purple",
    "#002c53": "Ferrario"
  }

  render() {
    const { testId } = this.props;

    return (
      <div className="ColorPicker__wrapper" data-test-id={testId} >
        <div onClick={this.handleClick} className="ColorPicker" style={{ backgroundColor: this.state.color }}></div>
        {
          this.state.displayColorPicker ?
            <div className="ColorPicker__modal"  >
              <div className="ColorPicker__popover">
                <div className="ColorPicker__heading ColorPicker__heading--custom">Select From Template</div>
                <div className="ColorPicker__layout" onClick={this.handleClose}></div>
                <div className={`${this.colorObj[this.state.tempColor] ? `Selected_${this.colorObj[this.state.tempColor]}` : ''}`}>
                  <SketchPicker
                    className="ColorPicker__sketch-picker"
                    color={this.state.tempColor}
                    disableAlpha={true} width="264px"
                    presetColors={this.presetColors}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="ColorPicker__heading ColorPicker__heading--template">Select Custom</div>
                <div className="ColorPicker__hex-code">{this.state.tempColor}</div>
                <div className="ColorPicker__actions">
                  <ButtonGroup>
                    <Button buttonType="light" onClick={this.handleClose}>Cancel</Button>
                    <Button buttonType="secondary" onClick={this.handleChoose}>Choose</Button>
                  </ButtonGroup>
                </div>

              </div>
            </div> : null
        }
      </div>
    )
  }
}


export default ColorPicker;