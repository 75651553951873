import React, { Component } from 'react';
import _ from 'lodash';
// import { components } from 'react-select'

class MultiValueLabels extends Component<any, any>{
  render() {
    const { children, getValue } = this.props;
    const newChildren = `${getValue().length} ${this.props.selectProps.selectedLabel || 'items selected'}`;

    if (getValue().length < 2) {
      return (
        <div className="multi-value-wrapper">
          {children}
        </div>
      );
    }
    return (
      <div className="multi-value-wrapper">
        {<>
          <span className="multi-value-count">{newChildren}</span>
          <span className="multi-value-list">{children}</span>
        </>}
      </div>
    );
  }
}

export default MultiValueLabels