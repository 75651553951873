import JsonStdZones from './timezonesStandard'
import JsonDSTZones from './timezonesDST'
import moment from 'moment-timezone'
import find from 'lodash/find';
import indexOf from 'lodash/indexOf';
import DSTTimeZoneList from './TimeZoneList'

//DatePicker
const getMonth = (value: any) => {
  let date = value ? value : new Date()
  return new Date(date).getMonth()
}
const getDay = (value: any) => {
  let date = value ? value : new Date()
  return new Date(date).getDate()
}
const getYear = (value: any) => {
  let date = value ? value : new Date()
  return new Date(date).getFullYear()
}
const getMinDate: any = (value: any) => {
  let year = getYear(value)
  let date = `${year - 20} 0${1} 0${1}`
  return new Date(date)
}
const getMaxDate = (value: any) => {
  let minDate = value ? new Date(value) : new Date()
  let year = getYear(minDate)
  let date = `${year + 20} ${12} ${31}`
  return new Date(date)
}
const getRegex = (type: any) => {
  if (type.includes('-')) return '^[0-9-]*$'
  //yyyy-MM-dd
  else return ''
}
const getInitialDate = (date: any, format?: any) => {
  if (format && format === "mm dd yyyy") {
    if (moment(date, "MM/DD/YYYY").toDate()) return moment(date, "MM/DD/YYYY").toDate()
  } else {
    if (moment(date, 'YYYY-MM-DD').toDate()) return moment(date).toDate()
    else throw new Error("Provide a Valid date in the format of 'yyyy-MM-dd'")
  }
}
const getStartDate = (date: any) => {
  if (new Date(date).getTime()) return new Date(date)
  else throw new Error("Provide a Valid date in the format of 'yyyy-MM-dd'")
}
const getEndDate = (date: any, startDate: any) => {
  let endDate = null
  let sDate = 0
  if (new Date(date).getTime()) endDate = new Date(date).getTime()
  else throw new Error("Provide a Valid date in the format of 'yyyy-MM-dd'")
  if (new Date(startDate).getTime()) sDate = new Date(startDate).getTime()
  else throw new Error("Provide a Valid date in the format of 'yyyy-MM-dd'")
  if (endDate > sDate) return new Date(date)
  else throw new Error('Provide a valid end date to the component')
}
const getFormat = (value: any) => {
  let format = value && value.toLowerCase()
  if (format === 'yyyy-mm-dd') return 'yyyy-MM-dd'
  else if (format === 'mm dd yyyy') return 'MM dd yyyy'
  else if (format === 'dd mmm yyyy') return 'dd MMM yyyy'
  else if (format === 'mmmm dd, yyyy') return 'MMMM dd, yyyy'
  else return 'yyyy-MM-dd'
}

//TimeZone standard

let StandardZoneOptions = (defaultZone: any) => {
  return Object.keys(JsonStdZones).map((zone) => {
    let obj: any = {}
    obj.label = zone
    obj.value = JsonStdZones[`${zone}`]
    obj.tooltip = zone
    // console.log('defaultZone ', defaultZone, ' obj.value ', obj.value)
    if (!defaultZone && obj.value === '+0530') {
      obj.default = true
    } else {
      delete obj.default
    }
    if (defaultZone && defaultZone === obj.value) {
      obj.default = true
    } else {
      delete obj.default
    }
    return obj
  })
}

// let StandardZoneOptions = (default) => {
//   return
// };

const getInitialTime = (value: any) => {
  let date = new Date(value).getTime() ? new Date(value) : null
  let hour = date ? String(date.getHours()).padStart(2, '0') : '00'
  let minute = date ? String(date.getMinutes()).padStart(2, '0') : '00'
  let seconds = date ? String(date.getSeconds()).padStart(2, '0') : '00'
  return `${hour}:${minute}:${seconds}`
}

//Timezone DST

let DSTZoneOptions = Object.keys(JsonDSTZones).map((zone) => {
  let obj: any = {}
  obj.label = zone
  obj.value = JsonDSTZones[`${zone}`]
  obj.tooltip = zone
  return obj
})

let formatTZLabel = (lbl: any, val: any, tzOffSet: any) => {
  let momentDate: any = moment();
  let newlbl = '';
  if (momentDate.tz(val).isDST()) {
    newlbl = lbl.replace(/\(GMT [\+\-][0-9]+:[0-9]{2}\)/g, "");
    if (newlbl.indexOf("Standard") > -1) {
      newlbl = newlbl.replace("Standard", "Daylight");
    } else {
      newlbl = newlbl.replace("Time", "Daylight Time");
    }
    return "(GMT " + (tzOffSet) + ") " + newlbl;
  } else {
    return lbl;
  }

};

//TimePicker
const getInitialTimeZone = (inputZone: any, ZoneOptions: any) => {
  let z = null
  ZoneOptions.forEach((zone: any) => {
    if (zone.value.includes(inputZone)) z = zone
  })
  return z
}

const defaultTimezone = (zoneValue: any) => {
  let filteredTimezones = DSTTimeZoneList();
  let defaultTimezone;
  if (!zoneValue) {
    let currentTimezone = moment.tz.guess();
    defaultTimezone = find(filteredTimezones, { 'value': currentTimezone }) ? currentTimezone : "";
    // For obsolute tzs name guessed by moment
    // console.log("moment.tz.guess() ", moment.tz.guess());
    let aliasTimezone;
    if (defaultTimezone === "") {
      for (let _i = 0; _i < filteredTimezones.length; _i++) {
        let aliasTimezoneIdx = filteredTimezones[_i].alias.length ? indexOf(filteredTimezones[_i].alias, currentTimezone) : -1;
        if (aliasTimezoneIdx >= 0) {
          aliasTimezone = filteredTimezones[_i];
          break;
        }
      }
      // console.log("aliasTimezone", aliasTimezone);
      defaultTimezone = aliasTimezone ? aliasTimezone.value : "";
    }
  } else {
    let zoneObj = find(filteredTimezones, { 'offsetString': zoneValue }) || '';
    defaultTimezone = zoneObj ? zoneObj.value : '';
  }
  return defaultTimezone;
}

export {
  getMonth,
  getDay,
  getYear,
  getMinDate,
  getMaxDate,
  getRegex,
  getInitialDate,
  getStartDate,
  getEndDate,
  getFormat,
  StandardZoneOptions,
  getInitialTimeZone,
  getInitialTime,
  DSTZoneOptions,
  // DSTTimeZoneList,
  defaultTimezone,
  formatTZLabel
}
