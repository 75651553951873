import React, { MouseEventHandler, RefObject, CSSProperties } from 'react'
import withDeprecatedProp from '../../utils/hooks/depricatedPropsHoc'
import './Radio.css'
import cn from 'classnames'

const defaultProps = {
  required: false,
  disabled: false,
  testId: 'cs-radio'
}

export interface RadioState {
  checked?: boolean
  disabled?: boolean
  value?: string
}

export type RadioProps = {
  className?: string
  /**
   * specifies that an checkbox input field must be filled out before submitting the form
  */
  checked?: boolean
  required?: boolean
  /**
   * The name prop specifies the name attribute of an checkbox input element
  */
  name?: string
  /**
   *The id prop specifies the id attribute of an checkbox input element 
  */
  id?: string
  /**
   * label on click of which it check/uncheck
  */
  label?: string
  /**
   *specifies if a checkbox is in checked state when the form is submitted 
  */
  value?: string
  /**
   *determines whether checkbox is disabled or not 
  */
  disabled?: boolean
  /**
   *Action that want to perform onclick of checkbox 
  */
  onClick?: MouseEventHandler
  /**
   *The onchange event occurs when the value of an element has been changed
  */
  onChange?: any
  style?: CSSProperties
  /**
   *The inputRef will add ref to the checkbox input field 
  */
  inputRef?: RefObject<HTMLInputElement>
  testId?: string
} & typeof defaultProps

class Radio extends React.Component<RadioProps, RadioState> {

  static defaultProps = defaultProps;

  render() {
    const {
      id,
      className,
      name,
      value,
      label,
      checked,
      onClick,
      onChange,
      required,
      disabled,
      inputRef,
      testId,
      ...otherProps
    } = this.props

    const classNames = cn('Radio', className, {
      'Radio--state-disabled': disabled
    })

    return (
      <label data-test-id={testId} className={classNames}>
        <input
          id={id}
          type="radio"
          name={name}
          value={value}
          checked={checked}
          onClick={e => {
            if (!disabled && onClick) {
              onClick(e)
            }
          }}
          onChange={e => {
            if (!disabled && onChange) {
              onChange(e)
            }
          }}
          ref={inputRef}
          required={required}
          disabled={disabled}
          {...otherProps}
        />
        <span className="Radio__box"></span>
        <span className="Radio__label">{label}</span>
      </label>
    )
  }
}

export default withDeprecatedProp(Radio, { 'text': 'label' })
