import React, { useState } from 'react'
import className from 'classnames'
import DatePicker from 'react-datepicker'
import Header from './Header'
import { format, isValid } from 'date-fns'
import 'react-date-range/dist/styles.css' // main css file
import 'react-datepicker/dist/react-datepicker.css'
import 'react-date-range/dist/theme/default.css' // theme css file
import './Datepicker.css'

import { getMinDate, getMaxDate, getInitialDate, getStartDate, getEndDate, getFormat } from '../DateTime/helper'

interface IDatepicker {
  initialDate?: any
  formatType?: string
  startDate?: Date
  endDate?: Date
  onChange?: (data?: any, from?: string) => void
  viewType?: string
  noBorder?: boolean
  selectsStart?: boolean
  selectsEnd?: boolean
  closeModal?: () => void
  testId?: string
}

const renderDayContents = (_, date) => {
  return (
    <div className="custom-day-date">
      <span className="custom-day-date__text">{date.getDate()}</span>
    </div>
  )
}

const Datepicker = (props: IDatepicker) => {
  const {
    initialDate,
    formatType,
    startDate,
    endDate,
    onChange,
    closeModal,
    viewType,
    noBorder,
    selectsStart,
    selectsEnd,
    testId
  } = props

  const type = formatType ? getFormat(formatType) : 'yyyy-MM-dd'
  const sDate = startDate && getStartDate(startDate)
  const eDate = endDate && getEndDate(endDate, sDate)
  const [date, setDate] = useState(initialDate ? getInitialDate(initialDate, formatType) : '')
  let validDate = ''
  if (date && isValid(date)) validDate = format(new Date(date), type)
  const [_, setValue] = useState(validDate)

  const calendarClickHandler = (item: any) => {
    let value = ''
    if (viewType === 'time' || viewType === 'datetime')
      value = new Date(item).getTime() ? format(item, 'yyyy-MM-dd') : item
    else value = new Date(item).getTime() ? format(item, type) : item
    setValue(value)
    onChange(value)
    if (viewType !== 'datetime' && closeModal) {
      closeModal()
    }

    setDate(item)
  }

  let minDate: any = sDate ? sDate : getMinDate(date)
  let maxDate: any = eDate ? eDate : getMaxDate(date)
  let dt: any = date || null

  return (
    <div
      data-test-id={testId}
      className={className('Datepicker', {
        'no-border': noBorder,
        'Datepicker__date-time': viewType === 'datetime'
      })}>
      <DatePicker
        inline
        renderCustomHeader={(headerProps) => <Header {...headerProps} maxDate={maxDate} minDate={minDate} />}
        renderDayContents={renderDayContents}
        minDate={minDate}
        maxDate={maxDate}
        onChange={calendarClickHandler}
        selected={dt}
        adjustDateOnChange={false}
        selectsStart={selectsStart}
        selectsEnd={selectsEnd}
      />
    </div>
  )
}

Datepicker.defaultProps = {
  testId: 'cs-datepicker',
  selectsStart: false,
  selectsEnd: false
} as Partial<IDatepicker>

export default Datepicker
