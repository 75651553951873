import React, { Component } from 'react'
import cn from 'classnames'
import Icon from '../Icon2/Icon'
import './ValidationMessage.css'

export type ValidationMessageProps = {
  className?: string
  style?: React.CSSProperties
  children: React.ReactNode
}

// const defaultProps = {};

export class ValidationMessage extends Component<ValidationMessageProps> {
  // static defaultProps = defaultProps;

  render() {
    const { className, children, ...otherProps } = this.props

    const classNames = cn('ValidationMessage', className)

    return (
      <div {...otherProps} className={classNames}>
        {/* <Icon icon="ErrorCircle" className={'ValidationMessage__icon'} /> */}
        <p className={'ValidationMessage__text'}>{children}</p>
      </div>
    )
  }
}

export default ValidationMessage
