const timeZone: any = [
  {
    value: "US/Samoa",
    label: "(GMT -11:00) Samoa Time",
    alias: ["Etc/GMT+11"]
  },
  {
    value: "Pacific/Honolulu",
    label: "(GMT -10:00) Hawaii Time, Honolulu"
  },
  {
    value: "Pacific/Tahiti",
    label: "(GMT -10:00) Tahiti Time"
  },
  {
    value: "US/Alaska",
    label: "(GMT -9:00) Alaska Time - Anchorage",
    alias: ["America/Anchorage"],
    dst: true,
    hour: 1
  },
  {
    value: "America/Los_Angeles",
    label: "(GMT -8:00) Pacific Time - Los Angeles",
    dst: true,
    hour: 1
  },
  {
    value: "America/Tijuana",
    label: "(GMT -8:00) Pacific Time - Tijuana",
    dst: true,
    hour: 1
  },
  {
    value: "America/Vancouver",
    label: "(GMT -8:00) Pacific Time - Vancouver",
    dst: true,
    hour: 1
  },
  {
    value: "America/Denver",
    label: "(GMT -7:00) Mountain Time - Denver",
    dst: true,
    hour: 1
  },
  {
    value: "America/Chihuahua",
    label: "(GMT -7:00) Mountain Time - Chihuahua",
    dst: true,
    hour: 1
  },
  {
    value: "America/Mazatlan",
    label: "(GMT -7:00) Mountain Time - Mazatlan",
    dst: true,
    hour: 1
  },
  {
    value: "America/Edmonton",
    label: "(GMT -7:00) Mountain Time - Edmonton",
    dst: true,
    hour: 1
  },
  {
    value: "America/Chicago",
    label: "(GMT -6:00) Central Time - Chicago",
    dst: true,
    hour: 1
  },
  {
    value: "America/Costa_Rica",
    label: "(GMT -6:00) Central Standard Time - Costa Rica",
    dst: true,
    hour: 1
  },
  {
    value: "America/Mexico_City",
    label: "(GMT -6:00) Central Time - Mexico City",
    dst: true,
    hour: 1
  },
  {
    value: "America/Winnipeg",
    label: "(GMT -6:00) Central Time - Winnipeg",
    dst: true,
    hour: 1
  },
  {
    value: "America/New_York",
    label: "(GMT -5:00) Eastern Time - New York",
    dst: true,
    hour: 1
  },
  {
    value: "America/Cancun",
    label: "(GMT -5:00) Eastern Standard Time - Cancun",
    offsets: [300],
    dst: false,
    hour: 1
  },
  {
    value: "America/Nassau",
    label: "(GMT -5:00) Eastern Time - Nassau",
    dst: true,
    hour: 1
  },
  {
    value: "America/Toronto",
    label: "(GMT -5:00) Eastern Time - Toronto",
    dst: true,
    hour: 1
  },
  {
    value: "Canada/Atlantic",
    label: "(GMT -4:00) Venezuela Time",
    dst: true,
    hour: 1
  },
  {
    value: "America/Halifax",
    label: "(GMT -4:00) Atlantic Time - Halifax",
    dst: true,
    hour: 1
  },
  {
    value: "Atlantic/Bermuda",
    label: "(GMT -4:00) Atlantic Time - Bermuda",
    dst: true,
    hour: 1
  },
  {
    value: "America/Puerto_Rico",
    label: "(GMT -4:00) Atlantic Standard Time - Puerto Rico",
    dst: true,
    hour: 1
  },
  {
    value: "America/Barbados",
    label: "(GMT -4:00) Atlantic Standard Time - Barbados",
    dst: true,
    hour: 1
  },
  {
    value: "Canada/Newfoundland",
    label: "(GMT -3:30) Newfoundland Time",
    alias: ["America/St_Johns"],
    dst: true,
    hour: 1
  },
  {
    value: "America/Argentina/Buenos_Aires",
    label: "(GMT -3:00) Argentina Standard Time - Buenos Aires",
    alias: ["America/Buenos_Aires", "America/Fortaleza"],
    dst: true,
    hour: 1
  },
  {
    value: "America/Montevideo",
    label: "(GMT -3:00) Uruguay Standard Time",
    offsets: [180],
    dst: false,
    hour: 1
  },
  {
    value: "America/Sao_Paulo",
    label: "(GMT -3:00) Brasilia Standard Time - Sao Paolo",
    offsets: [180],
    dst: false,
    hour: 1
  },
  {
    value: "Etc/GMT+2",
    label: "(GMT -2:00) Mid-Atlantic Time",
    alias: ["America/Noronha"]
  },
  {
    value: "Atlantic/Azores",
    label: "(GMT -1:00) Azores Time - Cape Verde",
    alias: ["Atlantic/Cape_Verde"],
    dst: true,
    hour: 1
  },
  {
    value: "Europe/London",
    label: "(GMT +00:00) GMT - London",
    dst: true,
    hour: 1
  },
  {
    value: "Europe/Lisbon",
    label: "(GMT +00:00) GMT - Lisbon",
    dst: true,
    hour: 1
  },
  {
    value: "Europe/Dublin",
    label: "(GMT +00:00) GMT - Dublin",
    dst: true,
    hour: 1
  },
  {
    value: "Africa/Casablanca",
    label: "(GMT +00:00) GMT - Casablanca",
    dst: true,
    hour: 1
  },
  {
    value: "Europe/Berlin",
    label: "(GMT +1:00) Central Europe Time - Berlin",
    dst: true,
    hour: 1
  },
  {
    value: "Europe/Paris",
    label: "(GMT +1:00) Central Europe Time - Paris",
    dst: true,
    hour: 1
  },
  {
    value: "Europe/Prague",
    label: "(GMT +1:00) Central Europe Time - Prague",
    alias: ["Europe/Budapest"],
    dst: true,
    hour: 1
  },
  {
    value: "Europe/Rome",
    label: "(GMT +1:00) Central Europe Time - Rome",
    dst: true,
    hour: 1
  },
  {
    value: "Europe/Zurich",
    label: "(GMT +1:00) Central Europe Time - Zurich",
    dst: true,
    hour: 1
  },
  {
    value: "Africa/Lagos",
    label: "(GMT +1:00) Central Europe Time - Lagos",
    dst: true,
    hour: 1
  },
  {
    value: "Asia/Jerusalem",
    label: "(GMT +2:00) Israel Time - Jerusalem",
    hour: 1
  },
  {
    value: "Europe/Helsinki",
    label: "(GMT +2:00) Eastern European Time - Helsinki",
    hour: 1
  },
  {
    value: "Europe/Kiev",
    label: "(GMT +2:00) Eastern European Time - Kiev",
    alias: ["Europe/Athens"],
    hour: 1
  },
  {
    value: "Asia/Beirut",
    label: "(GMT +2:00) Eastern European Time - Beirut",
    hour: 1
  },
  {
    value: "Africa/Johannesburg",
    label: "(GMT +2:00) South Africa Standard Time",
    hour: 1
  },
  {
    value: "Asia/Qatar",
    label: "(GMT +3:00) Arabian Standard Time - Qatar"
  },
  {
    value: "Africa/Nairobi",
    label: "(GMT +3:00) East Africa Time - Nairobi"
  },
  {
    value: "Europe/Moscow",
    label: "(GMT +3:00) Moscow Standard Time",
    offsets: [-180]
  },
  {
    value: "Asia/Tehran",
    label: "(GMT +3:30) Tehran",
    dst: true,
    hour: 1
  }, // old
  {
    value: "Iran",
    label: "(GMT +3:30) Iran Time",
    dst: true,
    hour: 1
  },
  {
    value: "Asia/Tbilisi",
    label: "(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi"
  }, // old
  {
    value: "Asia/Baku",
    label: "(GMT +4:00) Azerbaijan Standard Time"
  }, // Not got
  {
    value: "Asia/Dubai",
    label: "(GMT +4:00) Gulf Standard Time"
  }, // Not got
  {
    value: "Asia/Kabul",
    label: "(GMT +4:30) Afghanistan Time"
  },
  {
    value: "Indian/Maldives",
    label: "(GMT +5:00) Maldives Time"
  },
  {
    value: "Asia/Karachi",
    label: "(GMT +5:00) Pakistan Standard Time",
    alias: ["Asia/Tashkent"]
  },
  {
    value: "Asia/Kolkata",
    label: "(GMT +5:30) India Standard Time - Kolkata",
    alias: ["Asia/Calcutta"]
  },
  {
    value: "Asia/Kathmandu",
    label: "(GMT +5:45) Nepal Time",
    alias: ["Asia/Katmandu"]
  },
  {
    value: "Asia/Dhaka",
    label: "(GMT +6:00) Bangladesh Standard Time"
  },
  {
    value: "Asia/Bangkok",
    label: "(GMT +7:00) Indochina Time - Bangkok"
  },
  {
    value: "Asia/Jakarta",
    label: "(GMT +7:00) Western Indonesia Time - Jakarta"
  },
  {
    value: "Asia/Shanghai",
    label: "(GMT +8:00) China Standard Time - Shanghai"
  },
  {
    value: "Australia/Perth",
    label: "(GMT +8:00) Australia Western Standard Time - Perth"
  },
  {
    value: "Asia/Singapore",
    label: "(GMT +8:00) Singapore Standard Time"
  },
  {
    value: "Asia/Hong_Kong",
    label: "(GMT +8:00) Hong Kong Standard Time"
  },
  {
    value: "Asia/Manila",
    label: "(GMT +8:00) Phillipine Standard Time - Manila"
  },
  {
    value: "Asia/Tokyo",
    label: "(GMT +9:00) Japan Standard Time - Tokyo"
  },
  {
    value: "Asia/Seoul",
    label: "(GMT +9:00) Korea Standard Time - Seoul"
  },
  {
    value: "Australia/Darwin",
    label: "(GMT +9:30) Australian Central Standard Time - Darwin",
    dst: true,
    hour: 1
  },
  {
    value: "Australia/Brisbane",
    label: "(GMT +10:00) Australian Eastern Standard Time - Brisbane"
  }, // doubt about dst
  {
    value: "Pacific/Port_Moresby",
    label: "(GMT +10:00) Papua New Guinea Time"
  }, // doubt about dst
  {
    value: "Australia/Sydney",
    label: "(GMT +11:00) Eastern Australia Time - Sydney"
  }, // doubt about dst
  {
    value: "Pacific/Guadalcanal",
    label: "(GMT +11:00) Solomon Islands Time",
    alias: ["Asia/Sakhalin"]
  }, // doubt about dst
  {
    value: "Pacific/Majuro",
    label: "(GMT +12:00) Marshall Islands Time",
    dst: true,
    hour: 1
  },
  {
    value: "Pacific/Fiji",
    label: "(GMT +12:00) Fiji Time",
    offsets: [-720],
    dst: true,
    hour: 1
  }
];
export default timeZone
