/**
 * Important
 * Modal Component renders as a different mini-app within
 * sibling node of body invoke using callback
 **/

import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import ReactModal from 'react-modal'
import cn from 'classnames'
import classNames from 'classnames'

import './Modal.css'
import Icon from '../Icon2'
import Tooltip from '../Tooltip/Tooltip'
import { handleExtensionModal, handleExtensionModalClose } from './extension'

import withDeprecatedProp from '../../utils/hooks/depricatedPropsHoc';

const classNamesForSize = {
  tiny: 'ReactModal__Content--tiny',
  xsmall: 'ReactModal__Content--xsmall',
  small: 'ReactModal__Content--small',
  medium: 'ReactModal__Content--medium',
  large: 'ReactModal__Content--large',
  max: 'ReactModal__Content--max',
  dynamic: 'ReactModal__Content--dynamic-width',
  customSize: 'ReactModal__Content--custom-size',
}

export type Style = {
  content: object
  overlay: object
}

export type ModalProps = {
  /**
   * function will be called after modal will be close
   */
  onClose?: (data: any) => void
  /**
   * pass redux store if needed to connect component in modal
   */
  store?: any
  modalContentPosition?: string
  /**
   * String className to be applied to the modal
   */
  customClass?: any,
  canCloseOnRouteSwitch?: boolean
  /**
    * Object indicating styles to be used for the modal.
    * It has two keys, `overlay` and `content`.
    */
  style?: Style
  /**
   * Function that will be run after the modal has opened.
   */
  onOpen?: () => any
  /**
   * Number indicating the milliseconds to wait before closing the modal
   */
  closeTimeoutPeriod?: number
  /**
   * Boolean indicating if pressing the esc key should close the modal
   */
  shouldCloseOnEscape?: boolean
  /**
   * Boolean indicating if the overlay should close the modal
   */
  shouldCloseOnOverlayClick?: boolean
  /**
   * Specified string indicating size of modal
   */
  size?: 'tiny' | 'xsmall' | 'small' | 'medium' | 'large' | 'max';
  isSidebar?: Boolean
}

export type CbModalProps = {
  /**
   * React component to render within modal
   */
  component: React.ReactNode
  /**
   * Props to configure modal
   */
  modalProps?: ModalProps,
  /**
   *String testId that renders a data-testid attribute in the DOM,
    useful for testing
   */
  testId?: string
}

const defaultProps = {
  testId: 'cs-modal'
}

export type ReturnCbModalProps = {
  closeModal: (data?: any) => void
  update: (args: any) => void,
  parentDiv: any,
}

const cbModal = ({ component, modalProps, testId = defaultProps.testId }: CbModalProps): ReturnCbModalProps => {
  let { shouldCloseOnOverlayClick, onClose, store, ...rest } = modalProps || {}

  shouldCloseOnOverlayClick =
    typeof shouldCloseOnOverlayClick === 'boolean' ? shouldCloseOnOverlayClick : false

  const controlConfigs = {
    ...rest,
    isOpen: true,
    shouldCloseOnOverlayClick,
    testId,
  }

  let div = document.createElement('div')
  document.body.appendChild(div)

  function destroy() {
    const unmountResult = ReactDOM.unmountComponentAtNode(div)
    if (unmountResult && div.parentNode) {
      div.parentNode.removeChild(div)
    }
  }

  function onAfterClose(data: any) {
    destroy()
    if (onClose) {
      onClose(data)
    }
    handleExtensionModalClose()
  }

  function closeModal(data: any) {
    let currentConfigs = {
      ...controlConfigs,
      isOpen: false,
      onAfterClose: onAfterClose.bind(this, data),
    }
    render(currentConfigs)
  }

  function update(newConfig: any) {
    let currentConfig = {
      ...controlConfigs,
      ...newConfig,
      isOpen: true,
    }
    render(currentConfig)
  }

  function render(configs) {
    setTimeout(async () => {
      handleExtensionModal(configs).finally(() => {
        ReactDOM.render(
          store ? (
            <Provider store={store}>
              <Modal closeModal={closeModal} {...configs} component={component} />
            </Provider>
          ) : (
            <Modal closeModal={closeModal} {...configs} component={component} />
          ),
          div
        )
      })
    })
  }

  render(controlConfigs)

  return {
    closeModal,
    update,
    parentDiv: div
  }
}

export function Modal(props: any) {
  let {
    component: Component,
    closeModal,
    modalContentPosition,
    isSidebar,
    size,
    canCloseOnRouteSwitch,
    onOpen,
    closeTimeoutPeriod,
    shouldCloseOnEscape,
    testId,
    ...rest
  } = props

  canCloseOnRouteSwitch = typeof canCloseOnRouteSwitch === 'boolean' ? canCloseOnRouteSwitch : true

  const [fullscreenView, setFullscreenView] = useState(false)

  const onCloseModal = () => {
    if (props.isOpen && canCloseOnRouteSwitch) {
      closeModal()
    }
  }

  const onFullscreenModal = () => {
    setFullscreenView(!fullscreenView)
  }

  const closeFullscreen = () => {
    setFullscreenView(false)
  }

  useEffect(() => {
    document.addEventListener('closeCbModal', onCloseModal, false)
    return () => {
      document.removeEventListener('closeCbModal', onCloseModal, false)
    }
  }, [])

  return (
    <ReactModal
      isOpen={props.isOpen}
      closeTimeoutMS={closeTimeoutPeriod || 200}
      shouldCloseOnEsc={shouldCloseOnEscape}
      overlayClassName={cn('ReactModal__overlay-default flex-v-center', {
        'ReactModal__Overlay--with-transition': isSidebar,
      })}
      className={cn(
        `${modalContentPosition && modalContentPosition.length
          ? 'SidebarModal' + modalContentPosition
          : ''
        } ${fullscreenView
          ? 'ReactModal__Content--fullscreen'
          : classNamesForSize[size] || classNamesForSize['medium']
        } ${props.customClass ? props.customClass : ''}`
      )}
      ariaHideApp={false}
      onRequestClose={closeModal}
      onAfterOpen={onOpen}
      testId={testId}
      {...rest}
    >
      <Component
        closeModal={closeModal}
        onFullscreenModal={onFullscreenModal}
        closeFullscreen={closeFullscreen}
        fullscreenView={fullscreenView}
        isFullScreen={fullscreenView} 
        />
    </ReactModal>
  )
}

export function ModalHeader(props) {
  const [isMaximized, setMaximized] = useState(false)
  const onFullscreenModal = () => {
    setMaximized(!isMaximized)
    props.onFullscreenModal()
  }
  useEffect(() => {
    let isFullscreen = document.getElementsByClassName('ReactModal__Content--fullscreen')
    setMaximized(isFullscreen.length ? true : false)
  }, [])
  return (
    <div className={classNames("ReactModal__Content__header", "flex-justify", props.className)}>
      {props.title && <h3 title={(typeof props.title === 'string') ? props.title : ''}>{props.title}</h3>}
      <div className='flex-v-center'>
      {props.actionButtons && (
          <div className="ReactModal__header-action-buttons">
            {props.actionButtons}
          </div>
        )}
        {props.onFullscreenModal && (
          <Tooltip position="top" content={`${isMaximized ? 'Restore down' : 'Maximize'}`}>
            <div className="ReactModal__fullscreen flex-center" onClick={onFullscreenModal}>
              <Icon icon={`${isMaximized ? 'Compress' : 'Expand'}`} size="small" />
            </div>
          </Tooltip>
        )}
        {props.closeModal && (
          <Tooltip position='top' content='Close' appendTo={document.body}>
            <div className='ReactModal__close' onClick={props.closeModal}>
              <Icon icon='Cancel' />
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  )
}

export function ModalFooter(props) {
  return <div className='ReactModal__Content__footer flex-right'>{props.children}</div>
}

export function ModalBody(props) {
  const { className, children, ...rest } = props

  return (
    <div {...rest} className={`ReactModal__Content__body ${className ? className : ''}`}>
      {children}
    </div>
  )
}

export default withDeprecatedProp(
  { type: 'method', callback: cbModal },
  {
    modalProps: {
      onAfterOpen: 'onOpen',
      closeTimeoutMS: 'closeTimeoutPeriod',
      shouldCloseOnEsc: 'shouldCloseOnEscape',
    },
  }
)
