import React, { useState, useEffect } from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'

import withDeprecatedProp from '../../utils/hooks/depricatedPropsHoc';
import RenderTag from './RenderTag'

import './TagEditor.css'

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export type TagEditorProps = {
  tags: string[]
  label?: string
  onChange: (tagToUpdate: string[]) => any
  isSortable?: boolean
  placeholder?: string
  testId?: string
  error?: boolean
}

const TagEditor = (props: TagEditorProps) => {
  let isDragging = false
  let tags: any = props.tags && props.tags.length ? props.tags : []
  let sortTag = props.isSortable || false
  tags = tags.map((tag, i) => ({ label: tag, id: tag + i }))

  let [controlledTags, setControlledTags] = useState(tags)
  let [editing, setEditing] = useState(null)
  let [caret, setCaret] = useState(null)

  useEffect(() => {
    setControlledTags(tags)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags.map(tag => tag.label).join(',')])

  const removeTag = (index: number) => {
    let filteredTags = controlledTags
      .filter((tag, i) => i !== index)
      .map(tag => tag.label)
      .filter(Boolean)
    props.onChange(filteredTags)
  }

  const handleClick = () => {
    if (!isDragging) {
      let filteredTags = [...controlledTags, { label: '', id: `${controlledTags.length}` }]
      setEditing(filteredTags.length - 1)
      setCaret(0)
      setControlledTags(filteredTags)
    }
  }

  const handleTagSave = (tag, text) => {
    let filteredTags = [...controlledTags]

    let foundTag = filteredTags.find(t => t.id === tag.id)
    foundTag.label = text

    filteredTags = filteredTags.filter(t => t.label.trim())

    setControlledTags(filteredTags)
    setCaret(null)
    setEditing(null)
    props.onChange(filteredTags.map(tag => tag.label))
  }

  const onDragEnd = (result: any, draggableId) => {
    if (!result.destination) {
      // isDragging = false
      // const ele = document.querySelector(`#${result.draggableId}`)
      // ele.classList.remove('Tag--draggable');
      return
    }

    const filteredTags = reorder(controlledTags, result.source.index, result.destination.index)

    setControlledTags(filteredTags)
    props.onChange(filteredTags.map((tag: any) => tag.label))
    isDragging = false
    const ele = document.querySelector(`#${draggableId}`)
    ele.classList.remove('Tag--draggable');
  }

  const onDragStart = ({ draggableId }) => {
    isDragging = true
    const ele = document.querySelector(`#${draggableId}`)
    ele.classList.add('Tag--draggable');
  }

  const handleTagFocus = (tag, i) => {
    setEditing(i)
    setCaret(tag.label.length)
  }

  const disableEditing = () => {
    setEditing(null)
    setCaret(null)
  }

  if (!controlledTags.length) {
    return (
      <>
        {props.label && <p>{props.label}</p>}
        <div
          className={`Tag ${sortTag ? 'Tag--sortable' : ''} ${props.error ? 'Tag--error' : ''} ${typeof editing === 'number' ? ' Tag--active' : ''
            }`}
          onClick={handleClick}
        >
          <div className="Tag__placeholder">{props.placeholder}</div>
        </div>
      </>
    )
  }

  return (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
      {props.label && <p>{props.label}</p>}
      <Droppable droppableId="droppable" direction="horizontal" isDropDisabled={!sortTag}>
        {(provided: any, snapshot: any) => (
          <div
            data-test-id={props.testId}
            {...provided.droppableProps}
            ref={provided.innerRef}
            className={`Tag flex-wrap ${sortTag ? 'Tag--sortable' : ''} ${props.error ? 'Tag--error' : ''} ${typeof editing === 'number' ? ' Tag--active' : ''
              }`}
            onClick={handleClick}
          >
            {controlledTags.map((tag, i) => {
              return (
                <RenderTag
                  sortTag={sortTag}
                  index={i}
                  tagId={tag.id}
                  active={editing === i ? true : false}
                  caret={editing === i ? caret : null}
                  onSave={text => handleTagSave(tag, text)}
                  onFocus={() => handleTagFocus(tag, i)}
                  onRemove={() => removeTag(i)}
                  key={tag.id}
                  disableEditing={disableEditing}
                  handleClick={handleClick}
                >
                  {tag.label}
                </RenderTag>
              )
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

TagEditor.defaultProps = {
  testId: 'cs-editable-tags'
} as Partial<TagEditorProps>;

export const EditableTags = withDeprecatedProp(TagEditor, { 'sort': 'isSortable', 'updateTag': 'onChange' })

export default withDeprecatedProp(TagEditor, { 'sort': 'isSortable', 'updateTag': 'onChange' }, { 'TagEditor': 'EditableTags' })