import { memo, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
const Portal = ({ domId, children }) => {
  const el = useRef(document.getElementById(domId) || document.createElement('div'));
  const [dynamic] = useState(!el.current.parentElement)

  useEffect(() => {
    if (dynamic) {
      el.current.id = domId;
      document.body.appendChild(el.current);
    }
    return () => {
      if (dynamic && el.current.parentElement) {
        el.current.parentElement.removeChild(el.current);
      }
    }
  }, [domId]);

  return createPortal(children, el.current);
};
export default memo(Portal);