import React, { Component, PureComponent, useMemo } from 'react';
import { components } from 'react-select';
import _ from 'lodash';

const OptionTextDOM = ({ selectProps, menuClassName }: any) => {

  return useMemo(() => {
    return (
      <div onClick={selectProps.addOption} className={`Select__add-option flex-v-center ${menuClassName}`}>{selectProps.addOptionText}</div>
    )
  }, [selectProps, menuClassName])
}

const ChildrenDOM = ({ children }: any) => {

  return useMemo(() => {
    return (
      <>{children}</>
    )
  }, [children, children.props])
}

class Menus extends PureComponent<any, any> {

  render() {
    const { isDisabled, children, selectProps, menuStyle, menuClassName, ...rest } = this.props;
    delete this.props.innerProps.onMouseMove
    // console.log('this is the props check', this.props);
    return (
      <components.Menu {...this.props}>
        <div style={menuStyle}>
          <ChildrenDOM children={children} />
          <OptionTextDOM menuClassName={menuClassName} selectProps={selectProps} />
        </div>
      </components.Menu>
    )
  }
}

export default Menus