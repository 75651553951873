import React, { CSSProperties } from 'react'
import cn from 'classnames'
import './Line.css'

export type LineProps = {
  type: 'dashed' | 'solid',
  className?: string,
  style?: CSSProperties,
  /**
  * value in "px"
  */
  width?: number
}

const Line = (props: LineProps) => {
  const { type, className, style, width } = props;

  const classNames = cn('Line', className, {
    'Line--Dashed': type === 'dashed',
    'Line--Solid': type === 'solid',
  })
  return (
    <div
      className={classNames}
      style={{ width: width, ...style }}
    />
  )
}

export default Line;