import React, {
    Component,
    // ChangeEvent,
    // ChangeEventHandler,
    // FocusEventHandler,
    Fragment,
    CSSProperties
} from 'react';
import cn from 'classnames';
import FieldLabel from '../FieldLabel/FieldLabel';
import InstructionText from '../InstructionText/InstructionText';
import './Field.css';

export type FieldProps = {
    /**
   *Specifies the name attribute of the element.
   */
    name?: string; 
    /**
   *Specifies the id attribute of the element.
   */
    id?: string;
     /**
   *Sets the width of the field area. It can be ‘small’,‘medium’,‘large’ or ‘full’..
   */
    width?: 'small' | 'medium' | 'large' | 'full';
    /**
   *Lets you add a value to a field.
   */
    value?: string;
    /**
   *Defines the label of a field.
   */
    labelText?: string;
    
    /**
     *Sets a corresponding field state to  required, which means it cannot be left blank/empty.
     */
    required?: boolean;
    /**
     *Lets you add child elements defined within a component.
     */
    children?: React.ReactNode
    /**
     *Provides corresponding guidance to the field when hovered.
     */
    helpText?: string;
    maxLength?: number;
    countCharacters?: boolean;
    /**
     *Lets you disable a field.
     */
    disabled?: boolean;
    /**
     *Pass the class names to be appended to this prop.
     */
    className?: string;
    /**
     *Pass any CSS properties to be added to this component.
     */
    style?: CSSProperties;
    /**
     *Used for testing purposes.
     */
    testId?: string;
    // placeholder?: string;  //
    // validationMessage?: string; //
    // onChange?: ChangeEventHandler; //
    // onBlur?: FocusEventHandler; //
    // onFocus?: FocusEventHandler; //
    // fieldType?: //
    // | 'text'
    // | 'password'
    // | 'email'
    // | 'number'
    // | 'search'
    // | 'url'
    // | 'date'
    // | 'time'
    // | 'checkbox'
    // | 'radio';
} & typeof defaultProps;
export interface FieldState {
    value?: string;
    initialValue?: string;
}

const defaultProps = {
    required: false,
    countCharacters: false,
    width: 'full',
    testId: 'cs-field',
    // fieldType:'text',
    disabled:false
};
export class Field extends Component<FieldProps, FieldState> {
    static defaultProps = defaultProps;

    state = { value: this.props.value || '', initialValue: this.props.value };

    // handleOnChange = (evt: ChangeEvent) => {
    //     this.setState({ value: (evt.target as HTMLInputElement).value });
    //     if (this.props.onChange) this.props.onChange(evt);
    // };

    static getDerivedStateFromProps(
        props: FieldProps,
        state: FieldState,
    ) {
        if (props.value !== state.initialValue) {
            return { ...state, value: props.value, initialValue: props.value };
        }
        return state;
    }

    render() {
        const {
            className,
            testId,
            width,
            maxLength,
            children,
            labelText,
            helpText,
            countCharacters,
            disabled,
            required,
            value,
            name,
            id,
            // validationMessage,
            // fieldType,
            // onChange,
            // onBlur,
            // onFocus,
            // placeholder,
            ...otherProps
        } = this.props;

        const widthClass = `Field--${width}`;
        const classNames = cn('Field', widthClass, className, { 'Field--disabled': disabled });
        return (
            <div className={classNames} data-test-id={testId} {...otherProps}>
                {labelText && (
                    <div className={'Field__label-wrapper'}>
                        <FieldLabel htmlFor={id} required={required} disabled={disabled}>
                            {labelText}
                        </FieldLabel>

                    </div>
                )}
                {(helpText || countCharacters) && (
                    <div className={'TextField__hints'}>
                        {helpText && (
                            <InstructionText className={'TextField__help-text'}>
                                {helpText}
                            </InstructionText>
                        )}
                        {countCharacters && maxLength && (
                            <InstructionText
                                className={cn(
                                    'TextField__help-text',
                                    'TextField__count',
                                )}
                            >
                                {this.state.value ? this.state.value.length : 0}/
                                {maxLength}
                            </InstructionText>
                        )}
                    </div>
                )}
                {children && <Fragment>{children}</Fragment>}
            </div>
        );
    }
}
export default Field;