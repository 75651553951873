import './OutlineTag.css'

import React, { CSSProperties } from 'react'

import cn from 'classnames'

import withDeprecatedProp from '../../utils/hooks/depricatedPropsHoc'
import Icon from '../Icon2'

export type OutlineTagProps = {
  style?: CSSProperties,
  content?: string | React.ReactNode,
  className?: string,
  type?: "positive" | "primary",
  testId?: string
  hasLink?: boolean
  allowPropagation?: boolean
}

const OutlineTag = (props: OutlineTagProps) => {
  const { allowPropagation = false } = props
  const classNames = cn('OutlineTag', props.className, { [`OutlineTag--${props.type}`]: props.type })
  return (
    <>
      <div
        data-test-id={props.testId}
        className={classNames}
        style={props.style}
        onClick={allowPropagation ? undefined : (event) => event.stopPropagation()}
      >
        {props.content}
      </div>
      {props.hasLink
        ?
        <span className="information">
          <Icon icon="Information2" />
        </span>
        : null
      }
    </>
  )
}
OutlineTag.defaultProps = {
  testId: 'cs-outline-tag'
} as Partial<OutlineTagProps>;

export default withDeprecatedProp(OutlineTag, { 'text': 'content', 'data': 'content' })