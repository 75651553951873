import React from 'react'
import './AsyncLoader.css'

class AsyncLoader extends React.Component<any, any> {
  render() {
    let { className, color, ...otherProps } = this.props;
    color = color ? color : "#fff";

    return (
      <div className="AsyncLoader" {...otherProps}>
        <div className="AsyncLoader--dot" style={{ backgroundColor: color }}></div>
        <div className="AsyncLoader--dot" style={{ backgroundColor: color }}></div>
        <div className="AsyncLoader--dot" style={{ backgroundColor: color }}></div>
      </div>
    )
  }
}

export default AsyncLoader
