import React, { useEffect } from 'react'
import { useTable, useSortBy, usePagination, useRowSelect } from 'react-table'

import Button from '../Button/Button'
import Checkbox from '../Checkbox/Checkbox'
import Icon from '../Icon2/Icon'
import EmptyState from '../EmptyState/EmptyState'
import Dropdown from '../DropDown/Dropdown'

import './Table.css'

const TablePanel = props => {
  const {
    columnSelector,
    allColumns,
    totalCounts,
    dataType,
    viewSelector,
    dropDownList,
    viewBy,
  } = props

  let totalString = ''
  if (!totalCounts) {
    totalString = `No ${dataType.singular}`
  } else {
    totalString =
      totalCounts === 1
        ? `${totalCounts} ${dataType.singular}`
        : `${totalCounts} ${dataType.plural}`
  }

  //approach 1
  const list = []

  allColumns.forEach(column => {
    if (column.addToColumnSelector) {
      const Selector = () => (
        <Checkbox
          text={column.Header}
          {...column.getToggleHiddenProps()}
          disabled={column.default}
        />
      )
      list.push({ label: <Selector />, action: () => { } })
    }
  })

  return (
    <div className="TablePanel flex-v-center">
      <label className="TablePanel__list-count">{totalString}</label>

      <div className="TablePanel__actions">
        <div
          className={
            'TablePanel__actions__items ' +
            ((viewSelector && !columnSelector) || (!viewSelector && columnSelector)
              ? 'TablePanel__actions__items--single'
              : '')
          }
        >
          {viewSelector && (
            <Dropdown
              list={dropDownList}
              type="click"
              dropDownPosition="bottom"
              withArrow={true}
              className="flex-v-center"
            >
              {viewBy === 'Compact' ? <Icon icon="CompactView" /> : <Icon icon="ComfortView" />}
            </Dropdown>
          )}
          {columnSelector && (
            <Dropdown
              list={list}
              type="click"
              dropDownPosition="bottom"
              withArrow={true}
              className="flex-v-center"
              closeAfterSelect={false}
            >
              <Icon icon="ColumnSelector"></Icon>
            </Dropdown>
          )}
        </div>
      </div>
    </div>
  )
}

const TablePagination = (props: any) => {
  const {
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    pageIndex,
  } = props

  return (
    <div className="cs-table-pagination">
      <Button
        onClick={() => gotoPage(0)}
        disabled={!canPreviousPage}
        buttonType="light"
        icon="Leftmost"
        className="pagination-btn"
      ></Button>
      <Button
        onClick={() => previousPage()}
        disabled={!canPreviousPage}
        buttonType="light"
        icon="Left"
        className="pagination-btn"
      ></Button>
      <span className="page">Page</span>
      <span className="page-no">
        {pageIndex + 1}
        {/* of {pageOptions.length} */}
      </span>
      <Button
        onClick={() => nextPage()}
        disabled={!canNextPage}
        buttonType="light"
        icon="Right"
        className="pagination-btn"
      ></Button>
      <Button
        onClick={() => gotoPage(pageCount - 1)}
        disabled={!canNextPage}
        buttonType="light"
        icon="Rightmost"
        className="pagination-btn"
      ></Button>

      {/* <span>
        | Go to page:{' '}
        <input
          type="number"
          defaultValue={pageIndex + 1}
          onChange={e => {
            const page = e.target.value ? Number(e.target.value) - 1 : 0
            gotoPage(page)
          }}
          style={{ width: '100px' }}
        />
      </span>{' '} */}
      {/* <select
        value={pageSize}
        onChange={e => {
          setPageSize(Number(e.target.value))
        }}
      >
        {rowSizePerPage.map(pageSize => (
          <option key={pageSize} value={pageSize}>
            Show {pageSize}
          </option>
        ))}
      </select> */}
    </div>
  )
}

const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }: any, ref) => {
  const defaultRef = React.useRef()
  const resolvedRef: any = ref || defaultRef

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate
  }, [resolvedRef, indeterminate])
  // return <input type="checkbox" ref={resolvedRef} {...rest} id="rowSelect" />
  return <Checkbox inputRef={resolvedRef} {...rest} id="rowSelect" />
})

IndeterminateCheckbox.displayName = 'IndeterminateCheckbox'

const pushCheckboxInRow = (hooks: any, rowSelectCheckboxProp: any) => {
  hooks.visibleColumns.push((columns: any) => [
    {
      id: 'selection',
      disableSortBy: true,
      Header: ({ getToggleAllRowsSelectedProps }: any) => {
        return (
          <div className="flex-v-center">
            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
          </div>
        )
      },
      Cell: ({ row }: any) => {
        if (rowSelectCheckboxProp) {
          if (row.original[rowSelectCheckboxProp.key] === rowSelectCheckboxProp.value) {
            return (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            )
          }
          return <div className="no-checkbox-space"></div>
        } else {
          return (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          )
        }
      },
    },
    ...columns,
  ])
}

type IInitialSortBy = {
  id: string
  desc: boolean
}

type IRowSelectCheckboxProp = {
  key: string
  value: boolean
}

type IActionCbArg = {
  data: any[]
  type: string
}

type IOnRowSelectProp = {
  label: string | React.ReactNode
  cb: (actionCbArg: IActionCbArg) => void
}

type IDataType = {
  singular: string
  plural: string
}

type IColumns = {
  Header: string
  accessor: string
  default?: boolean
  disableSortBy?: boolean
  assetSmallView?: boolean
  Cell?: (props: any) => React.ReactNode
  addToColumnSelector?: boolean | false
  cssClass?: string
}

type ISortBy = {
  id: string
  sortingDirection: string
}

type IFetchTableDataArg = {
  skip: number
  limit: number
  pageIndex: number
  pageSize: number
  sortBy?: ISortBy | undefined
}

export type ITableProps = {
  columns: IColumns[]
  columnSelector?: boolean
  data: any[]
  dataType?: IDataType
  hiddenColumns?: string[]
  initialSortBy?: IInitialSortBy[] | []
  initialPageSize?: number
  initialPageIndex?: number
  pageCount?: number
  fetchTableData?: (fetchTableDataArg: IFetchTableDataArg) => void
  canPaginate?: boolean
  onRowClick?: (e: any) => void
  loading?: boolean
  isRowSelect?: boolean
  onRowSelectProp?: IOnRowSelectProp[]
  uniqueKey: string
  rowSelectCheckboxProp?: IRowSelectCheckboxProp
  isResetPage?: boolean
  initialSelectedRowIds?: object
  getSelectedRow?: Function
  totalCounts: number
}

const Table: React.FunctionComponent<ITableProps> = props => {
  //todo handle memoizing for data, column

  const rowSelectCheckboxProp = props.rowSelectCheckboxProp
  const columnSelector = typeof props.columnSelector === 'boolean' ? props.columnSelector : true
  const dataType = props.dataType || { singular: '', plural: '' } //asset select action (change)
  const canPaginate = typeof props.canPaginate === 'boolean' ? props.canPaginate : true
  const loading = typeof props.loading === 'boolean' ? props.loading : false
  const controlledPageSize = typeof props.initialPageSize === 'number' ? props.initialPageSize : 10
  const controlledPageIndex =
    typeof props.initialPageIndex === 'number' ? props.initialPageIndex : 0
  const controlledIsResetPage = typeof props.isResetPage === 'boolean' ? props.isResetPage : false

  const controlledSelectedRowIds =
    typeof props.initialSelectedRowIds === 'object' ? props.initialSelectedRowIds : {}

  const {
    getTableProps,
    getTableBodyProps,
    allColumns,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    // toggleAllRowsSelected,
    state: { sortBy, pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns: props.columns,
      data: props.data,
      initialState: {
        hiddenColumns: props.hiddenColumns || [],
        sortBy: props.initialSortBy || [],
        pageIndex: controlledPageIndex,
        pageSize: controlledPageSize,
        selectedRowIds: controlledSelectedRowIds,
      },
      disableMultiSort: true,
      manualSortBy: true,
      disableSortRemove: true,
      manualPagination: true,
      pageCount: props.pageCount,
      autoResetSelectedRows: true,
      autoResetPage: false,
      getRowId: (row: any) => row[props.uniqueKey],
    },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks: any) => {
      if (props.isRowSelect) {
        pushCheckboxInRow(hooks, rowSelectCheckboxProp)
      }
    }
  )

  const [selectedData, setSelectedData] = React.useState([])

  useEffect(() => {
    if (controlledIsResetPage) {
      gotoPage(0)
    }
    // (used to disable es lint warning for next line)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [controlledIsResetPage])

  useEffect(() => {
    const data = selectedFlatRows.map((rowData: any) => rowData.original)
    let allData: any = [...selectedData, ...data]
    const selectedRowIdsArr = Object.keys(selectedRowIds)

    allData = selectedRowIdsArr.map(id => allData.find((all: any) => all[props.uniqueKey] === id))
    allData = allData.filter(Boolean)

    //todo handle conditionally checkbox slected options and props name should be self explanatory
    if (rowSelectCheckboxProp) {
      allData = allData.filter(
        (data: any) => data[rowSelectCheckboxProp.key] === rowSelectCheckboxProp.value
      )
    }
    // let detailInfo: any = []
    // detailInfo = selectedRowIdsArr.map((id: any) => {
    //   return {
    //     [`${props.uniqueKey}`]: id
    //   }
    // })

    setSelectedData(allData)
    if (props.getSelectedRow) props.getSelectedRow(allData)
    // (used to disable es lint warning for next line)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [Object.keys(selectedRowIds).length])

  useEffect(() => {
    if (canPaginate && !controlledIsResetPage) {
      const skip = pageIndex * pageSize
      const limit = pageSize
      const fetchDataArgument: any = { skip, limit, pageIndex, pageSize }

      if (sortBy.length) {
        const { desc, id } = sortBy[0]
        const sortingDirection = desc ? 'desc' : 'asc'
        fetchDataArgument.sortBy = { sortingDirection, id }
      }

      props.fetchTableData && props.fetchTableData(fetchDataArgument)
    }
    // (used to disable es lint warning for next line)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [pageIndex, pageSize, sortBy])

  return (
    <>
      {selectedData.length ? (
        <RowSelectAction
          selectedData={selectedData}
          dataType={dataType}
          onRowSelectProp={props.onRowSelectProp}
        />
      ) : (
        <TablePanel
          columnSelector={columnSelector}
          allColumns={allColumns}
          dataType={dataType}
          totalCounts={props.totalCounts}
          loading={loading}
        />
      )}

      <div className="TableWrapper">
        <div {...getTableProps()} className="Table">
          <div className="Table__head">
            {headerGroups.map((headerGroup: any) => (
              <div {...headerGroup.getHeaderGroupProps()} className="Table__head__row">
                {headerGroup.headers.map((column: any) => {
                  if (column.id === 'selection') {
                    return (
                      <div
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                        className="Table-select-head flex-v-center"
                      >
                        {column.render('Header')}
                      </div>
                    )
                  }
                  return (
                    <div
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className={'Table__head__column ' +
                        (column.cssClass ? column.cssClass : '')
                      }
                    >
                      <div>
                        <span
                          className={
                            'Table__head__column-text ' +
                            (column.isSorted ? 'Table__head__column-text--sorted' : '')
                          }
                        // sortedColumnlogic style={{ color: column.isSorted ? 'red' : 'blue' }}
                        >
                          {column.render('Header')}
                        </span>
                        {!column.disableSortBy && (
                          <span className="sorting-wrapper">
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <Icon icon="DownArrowEnabled" size="small" hover={true} />
                              ) : (
                                <Icon
                                  icon="DownArrowEnabled"
                                  size="small"
                                  hover={true}
                                  className="table-header-sort-asc"
                                />
                              )
                            ) : (
                              <Icon
                                icon="DownArrowEnabled"
                                size="small"
                                className="half-opacity"
                                hover={true}
                              />
                            )}
                          </span>
                        )}
                      </div>
                    </div>
                  )
                })}
              </div>
            ))}
          </div>
          <div {...getTableBodyProps()} className="Table__body">
            {loading ? (
              Array(10)
                .fill(1)
                .map(() => {
                  return (
                    <div className="Table__body__row skeleton-row flex-v-center">
                      {headerGroups[0].headers.map((column: any) => {
                        if (column.id === 'selection') {
                          return (
                            <div className="Table-select-body flex-v-center">
                              <div className="skeleton-square"></div>
                            </div>
                          )
                        }
                        return (
                          <div className={'Table__body__column flex-v-center ' + (column.cssClass ? column.cssClass : '')}>
                            <div className="skeleton-rect"></div>
                          </div>
                        )
                      })}
                    </div>
                  )
                })
            ) : page.length ? (
              page.map((row: any) => {
                prepareRow(row)
                return (
                  <div
                    {...row.getRowProps()}
                    className={`Table__body__row ${selectedData.find(obj => row.id === obj.uid)
                      ? 'Table__body__row--selected'
                      : ''
                      }`}
                    onClick={
                      props.onRowClick
                        ? (e: any) => {
                          if (e.target.id !== 'rowSelect') {
                            props.onRowClick(row.original)
                          }
                        }
                        : null
                    }
                  >
                    {row.cells.map((cell: any) => {
                      if (cell.column.id === 'selection') {
                        return (
                          <div {...cell.getCellProps()} className="Table-select-body flex-v-center">
                            {cell.render('Cell')}
                          </div>
                        )
                      }
                      if (cell.column.assetSmallView) {
                        return null
                      } else {
                        return (
                          <div
                            {...cell.getCellProps()}
                            className={
                              'Table__body__column flex-v-center' +
                              (cell.column.cssClass ? cell.column.cssClass + ' ' : '') +
                              (cell.column.isSorted ? 'Table__body__column--sorted' : '')
                            }
                          // sortedColumnlogic style={{ color: cell.column.isSorted ? 'red' : 'blue' }}
                          >
                            {cell.render('Cell')}
                          </div>
                        )
                      }
                    })}
                    {/* {columnSelector && <div className="dummy-row-selector"></div>} */}
                  </div>
                )
              })
            ) : (
              <div className="no-table-data">
                <EmptyState
                  heading={'No Records Found'}
                  description={'Add some Assets'}
                  displayImage={false}
                />
                <div>No Records Found</div>
              </div>
            )}
          </div>
        </div>
        {canPaginate && (
          <TablePagination
            page={page}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            pageOptions={pageOptions}
            pageCount={pageCount}
            gotoPage={gotoPage}
            nextPage={nextPage}
            previousPage={previousPage}
            setPageSize={setPageSize}
            pageIndex={pageIndex}
            pageSize={pageSize}
          />
        )}
      </div>
    </>
  )
}

const RowSelectAction = ({ selectedData, dataType, onRowSelectProp }: any) => {
  return (
    <div
      className="TableActionPanel flex-justify flex-v-center"
      style={{ backgroundColor: 'rgb(108, 92, 231)' }}
    >
      <div>
        <span style={{ color: 'white', fontWeight: 'bolder' }}>
          {selectedData.length === 1
            ? `${selectedData.length} ${dataType.singular} selected `
            : `${selectedData.length} ${dataType.plural} selected `}
        </span>
      </div>
      <div>
        {onRowSelectProp.map((action: any, i: number) => {
          return (
            <Button
              icon={action.icon ? action.icon : undefined}
              key={i}
              buttonType={action.type ? action.type : 'secondary'}
              className={
                action.label === 'Unpublish'
                  ? 'Button--unpublish'
                  : ''
              }
              onClick={() => {
                //todo discus : gotopage(1)
                const type = typeof action.label === 'string' ? action.label.toLowerCase() : ''
                action.cb({ data: selectedData, type })
                // toggleAllRowsSelected(false)
              }}
            >
              {action.label}
            </Button>
          )
        })}
      </div>
    </div>
  )
}

Table.defaultProps = {
  rowSelectCheckboxProp: undefined,
  isRowSelect: undefined,
  onRowClick: undefined,
  loading: false,
  hiddenColumns: [],
  columnSelector: true,
  canPaginate: true,
  initialPageSize: 10,
  initialPageIndex: 0,
}

export default Table

// stateReducer: (newState, action, prevState) => {
//   console.log('newState', newState)
//   console.log('prevState', prevState)
//   console.log('action', action)
// }

//todo check and remove svg files
