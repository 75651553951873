//@ts-nocheck

function getStyleSheet() {
  let styleSheets = '';
  Array.from(document.styleSheets).forEach((sheet: CSSStyleSheet) => {
    //@ts-ignore
    styleSheets += sheet.ownerNode.outerHTML;
  })
  return styleSheets;
}

function getHTML(iframe) {
  if (iframe.getInnerHTML) {
    return iframe.getInnerHTML({ includeShadowRoots: true });
  }
  const dom = iframe;
  Array.from(dom.querySelectorAll('*')).forEach(el => {
    if (el.shadowRoot) {
      el.innerHTML = el.shadowRoot.innerHTML;
    }
  })
  return dom.innerHTML;
}

export async function handleExtensionModal(configs) {
  const { iframeRef, postRobot, getExtraProps } = window;
  if (!(iframeRef && postRobot)) return;
  const { isOpen } = configs;
  if (isOpen) {
    let payload = {
      dom: getHTML(iframeRef)
    }
    if (!window.isStyleSend || process.env.NODE_ENV !== "production") {
      payload.styles = getStyleSheet()
    }
    if (typeof getExtraProps === 'function') {
      payload = { ...payload, ...getExtraProps() }
    }
    await postRobot.sendToParent('DOMCopy', payload);
    const alreadyFullscreen = iframeRef.querySelector('#scrte-editor')?.classList.contains('fullscreen');
    if (!alreadyFullscreen)
      iframeRef.style.opacity = 0;
    await postRobot.sendToParent('goFullscreen', {
      open: true
    });
    window.isStyleSend = true
  }
}
export async function handleExtensionModalClose() {
  try {
    const { iframeRef, postRobot } = window
    if (!(iframeRef && postRobot)) return
    const scrteEditor = document.querySelector('#scrte-editor')
    const isAlreadyFullScreen = scrteEditor && scrteEditor.classList.contains('fullscreen') || false
    const fullscreen = document.body.classList.contains(
      "ReactModal__Body--open"
    )
    if (!isAlreadyFullScreen) {
      if (!fullscreen) {
        await postRobot.sendToParent('goFullscreen', {
          open: false
        });
        iframeRef.style.opacity = null;
      } else {
        iframeRef.style.opacity = 0;
      }
    }
  } catch (error) {

  }
}