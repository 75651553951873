/**
 * This table component provides infinite scrolling
 * with virtual rendering
 **/
import React, { useEffect, useRef, useState } from 'react'
import { useTable, useSortBy, usePagination, useRowSelect, useColumnOrder } from 'react-table'
import { FixedSizeList } from 'react-window'
import withDeprecatedProp from '../../utils/hooks/depricatedPropsHoc'
import InfiniteLoader from 'react-window-infinite-loader'
import AutoSizer from "react-virtualized-auto-sizer";


import Button from '../Button/Button'
import ButtonGroup from '../Button/ButtonGroup/ButtonGroup'
import Checkbox from '../Checkbox/Checkbox'
import Icon from '../Icon2/Icon'
import EmptyState from '../EmptyState/EmptyState'
import ActionTooltip from '../ActionTooltip/ActionTooltip'
import Dropdown from '../DropDown/Dropdown'
import SkeletonTile from '../SkeletonTile/SkeletonTile'
import Search from '../Search/Search'
import Tooltip from '../Tooltip/Tooltip'
import TextInput from '../TextInput/TextInput'


import './Table.css'
import InlineForms from '../InlineForms/InlineForms';
import DragDropFile from '../DragDrop/DragDropFile'

const defaultValue = {
  columnSelector: true,
  name: { singular: '', plural: '' },
  controlledSelectedRowIds: {},
  minBatchSizeToFetch: 30,
  loading: false,
  viewSelector: false,
  fetchTableDataArg: { skip: 0, limit: 30, startIndex: 0, stopIndex: 29 },
  canSearch: false,
  canRefresh: false,
  tableHeight: 650,
  toolTipOffset: 30,
  withExportCta: {
    component: <></>,
    showExportCta: false
  },
  baseColumnWidth: 140,
  canOrderColumn: false
}

const constants = {
  compact_view: 'Compact',
  comfort_view: 'Comfortable',
  asc: 'asc',
  desc: 'desc',
}

const viewByName = {
  compact_view: constants.compact_view,
  comfort_view: constants.comfort_view,
}

export type InitialSortByProp = {
  id: string
  desc: boolean
}

export type RowSelectCheckboxProp = {
  key: string
  value: boolean
}

export type ActionCbArgProp = {
  data: any[]
  type: string
}

export type OnRowSelectProp = {
  label: string | React.ReactNode
  cb: (actionCbArg: ActionCbArgProp) => void
  type?: string
  icon?: string,
  showSelected?: boolean
}

export type NameProp = {
  singular: string
  plural: string
}

export type ColumnsProp = {
  Header: string
  accessor: string | Function
  default?: boolean
  disableSortBy?: boolean
  Cell?: (props: any) => React.ReactNode
  addToColumnSelector?: boolean | false
  id?: string
  cssClass?: string
  columnWidthMultiplier?: number
}

export type SortByProp = {
  id: string
  sortingDirection: string
}

export type FetchDataArgProp = {
  skip: number
  limit: number
  sortBy?: SortByProp | undefined
  startIndex: number
  stopIndex: number
  searchText?: string
}

export type ItemStatusMapProp = {
  [index: number]: 'loading' | 'loaded'
}

export type TooltipListProp = {
  label: string | React.ReactNode
  title: string
  action: Function
  displayType: string
}

export type ExportCtaProp = {
  component: React.ReactNode
  showExportCta: boolean
}

export type RowDisableProp = {
  key: string
  value: boolean
}

const defaultProps = {
  columnSelector: true,
  name: { singular: '', plural: '' },
  controlledSelectedRowIds: {},
  minBatchSizeToFetch: 30,
  loading: false,
  viewSelector: false,
  canSearch: true,
  canRefresh: true,
  tableHeight: 650,
  withExportCta: {
    component: <></>,
    showExportCta: false
  }
}

type DefaultProps = Readonly<typeof defaultProps>

//todo add storybook tableprops with one liner descr.
export type TableProps = {
  /**
   * The core columns configuration object for the entire table 
  */
  columns: ColumnsProp[]
  /**
   * Show and hide, column selectors filters 
  */
  columnSelector?: boolean
  /**
   * The Data array that you want to display on table 
  */
  data: any[]
  /**
   * Used in rowselect header and table panel items counts 
  */
  name?: NameProp
  /**
   * If a column's ID is contained in this array, it will be hidden 
  */
  hiddenColumns?: string[]
  /**
   * used for sort data in table initially 
  */
  initialSortBy?: InitialSortByProp[] | []
  /**
   * Calls when searchText, sortBy changes with `FetchDataArgProp` 
  */
  fetchTableData: (fetchTableDataArg: FetchDataArgProp) => void
  /**
   * Click handler to attach onrowclick, calls with row data 
  */
  onRowClick?: (e: any) => void
  /**
   * Used in other parts of table to track status of data 
  */
  loading?: boolean
  /**
   * Pass true to add checkbox in row 
  */
  isRowSelect?: boolean
  /**
   * Used for bulk opeartion on selected row 
  */
  onRowSelectProp?: OnRowSelectProp[]
  /**
   * Used internally for table operation, rendering unique row data 
  */
  uniqueKey: string
  /**
   * Used for conditinally add select box in a row 
  */
  rowSelectCheckboxProp?: RowSelectCheckboxProp
  /**
   * pass the rowIds which will be selected at table initial load 
  */
  initialSelectedRowIds?: object
  /**
   * calls getSelectedRow method  with all selected row ids 
  */
  getSelectedRow?: Function
  /**
   * Min batch size or data size(limit) to fetch on scroll 
  */
  minBatchSizeToFetch?: number
  /**
   * Table uses this to track based of value of index if loading shows skeleton loader, if loaded shows data 
  */
  itemStatusMap: ItemStatusMapProp
  /**
   * Table calls this to fetch data with `FetchDataArgProp` 
  */
  loadMoreItems: (loadMoreItemsArg: FetchDataArgProp) => void
  /**
   * Total counts of all data 
  */
  totalCounts: number
  /**
   * Size of a item basically row height 
  */
  itemSize?: number
  /**
   * Pass it if you need to control scroll or smth with ref 
  */
  fixedlistRef?: any
  /**
   * Show and hide table view selector 
  */
  viewSelector?: boolean
  /**
   * Header text when there is no data available 
  */
  emptyHeading?: any
  /**
   * Description for emptystate 
  */
  emptyDescription?: any
  /**
   * we can also use this prop for empty state of table 
  */
  emptyObj?: any
  /**
   *Get table view when changes, Comfort or Compact 
  */
  getViewByValue?: (selectedViewBy: keyof typeof viewByName) => void
  /**
   * get position  value of scrollOffset  
  */
  getScrollPostion?: (scrollOffset: number) => void
  /**
   * Pass it for Actions tooltip on hover of row 
  */
  onHoverActionList?: Array<TooltipListProp>
  /**
   * use this props to make the entire row of table selectable 
  */
  fullRowSelect?: boolean
  /**
   * Pass true to add search bar on top of table
  */
  canSearch?: boolean
  /**
   * Pass true to add refresh icon on top of table 
  */
  canRefresh?: boolean
  /**
   * Placeholder for search 
  */
  searchPlaceholder?: string
  /**
   * Input value for search 
  */
  searchValue?: string
  /**
   * Pass it for single row select, use onRowClick to set it 
  */
  singleSelectedRowId?: string
  /**
   * Height for table  
  */
  tableHeight?: number
  /**
   * Pass it for different set of Actions tooltip on hover of row based on some condition 
  */
  onHoverListCondition?: any
  /**
   * determines if single row of table is selectable or not based key and value passed in obj to this prop 
  */
  conditionalSingleSelect?: any
  /**
   * With every action that is dispatched to the table's internal `React.useReducer` instance, this reducer is called and is allowed to modify the final state object for updating. 
  */
  tableStateReducer?: any
  /**
   * decides maximum selected of the data of table 
  */
  maxSelect?: number,
  /**
   * table will use this to load with initial selected data 
  */
  initialRowSelectedData?: [],
  /**
   * Pass the number of static row you add in the table 
  */
  staticRowCount?: number
  // selectKeyList?: string[]
  /**
   * will make all columns of table equal in width 
  */
  equalWidthColumns?: boolean,
  /**
   * calls with updated columnSelector data  when column selector is changed 
  */
  onToggleColumnSelector?: Function,
  /**
   * use this when we want open in new tab support for table row that has link 
  */
  linkToRow?: string | Function,
  /**
   * load custom component beside viewSeletor and columnSelector 
  */
  withExportCta?: ExportCtaProp
  testId?: string
  customRowAdd?: boolean
  onSaveChange?: Function
  resetSearch?: boolean,
  hideTablePanel?: boolean,
  LinkComponent?: Function,
  customRowComponent?: React.ReactNode
  /**
   * Used for conditinally disable a row 
  */
  rowDisableProp?: RowDisableProp
  /**
   * calls onHoverText method  with current row data 
  */
  onRowHoverText?: Function
  /**
    * Pass true to enable ordering of table column from column selector 
   */
  canOrderColumn?: boolean,
  /**
   * Table uses order of column's ID array to set its column order
  */
  columnsOrder?: string[],
  /**
   * calls with updated column orders when column's orders is changed 
  */
  onChangeColumnOrder?: Function

} & Partial<DefaultProps>

const getTableRowClassString = (row, isRowDisabled, rowSelectCheckboxProp, viewBy, isSingleRowSelected, equalWidthColumns, rowDisableProp) => {
  let classname: string = 'Table__body__row ';
  if (isRowDisabled) {
    classname = classname + 'Table__body__row--disabled ';
  };
  if (equalWidthColumns) {
    classname = classname + 'Table__body__row--equal-width-columns ';
  }
  if (row.isSelected) {
    if (rowSelectCheckboxProp && row.original[rowSelectCheckboxProp.key] === rowSelectCheckboxProp.value) {
      if (!(rowDisableProp && row.original[rowDisableProp.key] === rowDisableProp.value)) {
        classname = classname + 'Table__body__row--selected ';
      }
    }
    if (!rowSelectCheckboxProp) {
      if (!(rowDisableProp && row.original[rowDisableProp.key] === rowDisableProp.value)) {
        classname = classname + 'Table__body__row--selected ';
      }
    };
  };
  if (viewBy === 'Compact') {
    classname = classname + 'Table__body__row--compact ';
  };
  if (isSingleRowSelected) {
    classname = classname + 'Table__body__row--selected ';
  };
  if (rowDisableProp && row.original[rowDisableProp.key] === rowDisableProp.value) {
    classname = classname + 'Table__body__row--complete-disabled '
  }
  return classname;
};

const getRowsLength = (rows, rowSelectCheckboxProp) => {
  let length = rows.length
  if (rowSelectCheckboxProp) {
    let count = 0
    rows.forEach((row) => {
      if (row.original[rowSelectCheckboxProp.key] === rowSelectCheckboxProp.value) {
        count = count + 1
      }
    })
    length = count
  }
  return length
}

const reorderList = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const filterColumnData = (allColumns) => {
  let columnObject = {}
  allColumns.forEach(column => {
    if (column.addToColumnSelector) {
      columnObject[column.id] = column.isVisible
    }
  })
  return columnObject
}

const SingleRowSelectedUi = (props) => {
  return (
    <>
      {props.children}
      <Icon icon="SelectSingle" size="original" className="single-select" />
    </>
  )
}

const IndeterminateCheckbox = React.forwardRef(({ indeterminate, maxSelect, selectedData = [], rowData = {}, uniqueKey, totalCount, ...rest }: any, ref) => {
  const [disable, setDisable] = useState(false)
  const maxSelectInt = parseInt(maxSelect)
  const defaultRef = React.useRef()
  const resolvedRef: any = ref || defaultRef

  useEffect(() => {
    if (
      selectedData.length >= maxSelectInt &&
      rowData &&
      !selectedData.find((data) => data[uniqueKey] === rowData[uniqueKey]) &&
      !rowData.isHeader
    ) {
      setDisable(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [indeterminate])

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resolvedRef, indeterminate])

  const headerCheckboxCondCheck = rowData.isHeader && !rest.rowsLength

  const tooltipContent = headerCheckboxCondCheck
    ? 'Cannot bulk select any items in the list below'
    : disable || (rowData.isHeader && maxSelectInt === selectedData.length)
      ? 'Bulk selection limit reached'
      : ''

  return (
    tooltipContent ? (
      <div className='max-select-tooltip'>
        <Tooltip content={tooltipContent} position="bottom">
          <Checkbox
            inputRef={resolvedRef}
            {...rest}
            id='rowSelect'
            disabled={headerCheckboxCondCheck ? true : disable}
          />
        </Tooltip>
      </div>
    ) : (
      <Checkbox
        inputRef={resolvedRef}
        {...rest}
        id='rowSelect'
        disabled={headerCheckboxCondCheck ? true : disable}
      />
    )
  )
})

const pushCheckboxInRow = (
  hooks,
  { rowSelectCheckboxProp, selectedData, maxSelect, totalCount, uniqueKey, LinkComponent, rowDisableProp }
) => {
  hooks.visibleColumns.push((columns: any) => [
    {
      id: 'selection',
      disableSortBy: true,
      Header: ({
        getToggleAllRowsSelectedProps,
        toggleRowSelected,
        toggleAllRowsSelected,
        selectedFlatRows,
        rows
      }: any) => {
        let checkboxProps = { ...getToggleAllRowsSelectedProps() }
        const rowsLength = getRowsLength(rows, rowSelectCheckboxProp)
        if (maxSelect) {
          let currentSelectLimit = maxSelect - selectedData.length

          const overriddenChecked =
            selectedData.length &&
            rows.length &&
            (selectedData.length === maxSelect || (rowsLength < maxSelect && selectedFlatRows.length === rowsLength)
              ? true
              : false)
          const overriddenIndeterminate = !overriddenChecked && selectedData.length > 0

          const overriddenOnChange = (e) => {
            if (currentSelectLimit > 0 && e.currentTarget.checked) {
              let count = 0
              for (let i = 0; i < rows.length; i++) {
                let tableRow = rows[i]
                if (count >= currentSelectLimit) {
                  return
                }
                if (rowSelectCheckboxProp) {
                  if (
                    tableRow.original[rowSelectCheckboxProp.key] === rowSelectCheckboxProp.value &&
                    !tableRow.isSelected
                  ) {
                    toggleRowSelected(tableRow.id, e.currentTarget.checked)
                    count = count + 1
                  }
                } else if (!tableRow.isSelected) {
                  count = count + 1
                  toggleRowSelected(tableRow.id, e.currentTarget.checked)
                }
              }
            } else if (!e.currentTarget.checked) {
              toggleAllRowsSelected(e.currentTarget.checked)
            }
          }
          checkboxProps = {
            ...checkboxProps,
            onChange: overriddenOnChange,
            checked: overriddenChecked,
            indeterminate: overriddenIndeterminate,
          }
        }
        return (
          <div className='flex-v-center'>
            <IndeterminateCheckbox
              {...checkboxProps}
              totalCount={totalCount}
              selectedData={selectedData}
              rowData={{ isHeader: true }}
              maxSelect={maxSelect}
              uniqueKey={uniqueKey}
              LinkComponent={LinkComponent}
              rowsLength={rowsLength}
            />
          </div>
        )
      },
      Cell: ({ row }: any) => {
        if (rowSelectCheckboxProp) {
          if (row.original[rowSelectCheckboxProp.key] === rowSelectCheckboxProp.value) {
            if (rowDisableProp && row.original[rowDisableProp.key] === rowDisableProp.value) {
              return (
                <div className="checkbox-wrapper" >
                  <Checkbox disabled={true} />
                </div>
              )
            }
            return (
              <div className="checkbox-wrapper">
                <IndeterminateCheckbox
                  {...row.getToggleRowSelectedProps()}
                  totalCount={totalCount}
                  rowData={row.original}
                  selectedData={selectedData}
                  maxSelect={maxSelect}
                  uniqueKey={uniqueKey}
                  LinkComponent={LinkComponent}
                />
              </div>
            )
          }
          return <div className="no-checkbox-space"></div>
        } else {
          if (rowDisableProp) {
            if (row.original[rowDisableProp.key] === rowDisableProp.value) {
              return (
                <div className="checkbox-wrapper" >
                  <Checkbox disabled={true} />
                </div>
              )
            }
          }
          return (
            <div className="checkbox-wrapper">
              <IndeterminateCheckbox
                {...row.getToggleRowSelectedProps()}
                totalCount={totalCount}
                rowData={row.original}
                selectedData={selectedData}
                maxSelect={maxSelect}
                uniqueKey={uniqueKey}
                LinkComponent={LinkComponent}
              />
            </div>
          )
        }
      },
    },
    ...columns,
  ])
}

const RowSelectAction = ({ selectedData, name, onRowSelectProp }: any) => {
  const hanldeClick = (action) => {
    const type = typeof action.label === 'string' ? action.label.toLowerCase() : ''
    action.cb({ data: selectedData, type })
  }
  let showSelectCtaDom: any;
  let hasShowSelectCta: boolean;
  // eslint-disable-next-line react-hooks/exhaustive-deps 
  onRowSelectProp.map((action: any, i: number) => {
    if (action.showSelected) {
      hasShowSelectCta = true
      showSelectCtaDom = <ButtonGroup>
        <Button
          key={i}
          buttonType={action.type ? action.type : 'primary'}
          icon={action.icon ? action.icon : undefined}
          onClick={() => hanldeClick(action)}
          className={action.label === 'Unpublish' ? 'Button--unpublish' : ''}
        >
          {action.label}
        </Button>
      </ButtonGroup>
    }
  })

  return (
    <div className="TableActionPanel flex-justify flex-v-center">
      <div className="selected-items">
        {
          hasShowSelectCta ?
            showSelectCtaDom
            : <span>
              {selectedData.length === 1
                ? `${selectedData.length} ${name.singular} selected `
                : `${selectedData.length} ${name.plural} selected `}
            </span>
        }
      </div>
      <div>
        <ButtonGroup>
          {
            // eslint-disable-next-line react-hooks/exhaustive-deps 
            onRowSelectProp.map((action: any, i: number) => {
              if (!action.showSelected) {
                return (
                  <Button
                    key={i}
                    buttonType={action.type ? action.type : 'primary'}
                    icon={action.icon ? action.icon : undefined}
                    onClick={() => hanldeClick(action)}
                    className={action.label === 'Unpublish' ? 'Button--unpublish' : ''}
                  >
                    {action.label}
                  </Button>
                )
              }
            })
          }
        </ButtonGroup>
      </div>
    </div>
  )
}

const TablePanel = (props) => {

  const {
    columnSelector,
    allColumns,
    totalCounts,
    name,
    viewSelector,
    dropDownList,
    viewBy,
    loading,
    searchPlaceholder,
    onChangeSearch,
    onRefresh,
    searchValue,
    canSearch,
    canRefresh,
    staticRowCount,
    onToggleColumnSelector,
    withExportCta,
    setTableColumnOrder,
    canOrderColumn
  } = props

  let totalString: any = ''

  if (loading) {
    totalString = (
      <SkeletonTile
        numberOfTiles={1}
        tileHeight={10}
        tileWidth={80}
        tileBottomSpace={7}
        tileTopSpace={5}
        tileleftSpace={5}
      />
    )
  } else {
    // check if the total count is present
    if (totalCounts) {
      totalString = `${totalCounts} ${name.plural}`;
      // check if the totalCounts is only 1 
      if (totalCounts === 1) {
        totalString = `${totalCounts} ${name.singular}`;
        // check if there is static row and the total count is only 1 so basically we need to show that there is no data here
        if (staticRowCount) {
          totalString = `No ${name.plural}`
        }
      }
      else {
        //where totalCount is not one and staticRowCount is present
        if (staticRowCount) {
          totalString = `${totalCounts - staticRowCount} ${name.plural}`
          //to handle the case where the staticRowCount is 1 and totalCount is 2 so the text should be singular
          if ((totalCounts - staticRowCount) === 1) {
            totalString = `${totalCounts - staticRowCount} ${name.singular}`
          }
        }
      }
    }
    else {
      totalString = `No ${name.plural}`
    }
  };

  const onChangeColumnSelector = (allColumnsData) => {
    if (onToggleColumnSelector) {
      const columnSelectorData = filterColumnData(allColumnsData)
      onToggleColumnSelector(columnSelectorData)
    }
  }

  const Selector = ({ column }) => (
    <Checkbox text={column.Header} {...column.getToggleHiddenProps()} disabled={column.default} />
  )

  const list = []
  allColumns.forEach((column) => {
    if (column.addToColumnSelector) {
      const action = () => {
        setTimeout(() => { onChangeColumnSelector(allColumns) })
      }
      list.push({ label: <Selector column={column} />, action, disable: column.default, id: column.id, canDragDrop: column.canDragDrop })
    }
  })

  const onDragEnd = (result, draggableId) => {
    if (!result.destination) {
      return
    }
    // const filteredColumn = list.filter(column => column.id !== 'selection')
    const updatedList: any = reorderList(list, result.source.index, result.destination.index)
    const updatedOrderedColumnIds = updatedList.map(list => list.id)
    setTableColumnOrder(updatedOrderedColumnIds)
  }

  return (
    <div className="TablePanel flex-v-center">
      {canSearch && (
        <Search
          width="full"
          corners="regular"
          placeholder={searchPlaceholder}
          onChange={onChangeSearch}
          debounceSearch={true}
          value={searchValue}
        />
      )}
      <div className={`flex-v-center ${canSearch ? '' : 'w-100 flex-justify'}`}>
        <label className="TablePanel__list-count flex-v-center">
          <div>{totalString}</div>
          {canRefresh && (
            <Tooltip content={'Refresh'} position="top">
              <div className="ml-10 refresh-wrapper" onClick={onRefresh} >
                <Icon icon="Refresh" className="Refresh__icon" />
              </div>
            </Tooltip>
          )}
        </label>
        {columnSelector || viewSelector || (withExportCta && withExportCta.showExportCta) ?
          <div className={`TablePanel__actions ml-20`}>
            <div
              className={
                'TablePanel__actions__items ' +
                ((viewSelector && !columnSelector) || (!viewSelector && columnSelector)
                  ? 'TablePanel__actions__items--single'
                  : '')
              }
            >
              {
                withExportCta && withExportCta.showExportCta && withExportCta.component && (
                  <div className="import-cta">{withExportCta.component}</div>
                )

              }
              <div className={`flex-v-center ${totalCounts === 0 ? 'TablePanel__actions--disabled' : ''}`}>
                {viewSelector && (
                  <Dropdown
                    list={dropDownList}
                    type="click"
                    dropDownPosition="bottom"
                    withArrow={true}
                    className="flex-v-center view-selector"
                    withIcon={true}
                  >
                    <Tooltip content={'Change table view'} position={'top'}>
                      {viewBy === constants.compact_view ? (
                        <Icon icon="CompactView" className="Compact__icon" />
                      ) : (
                        <Icon icon="ComfortView" className="Comfort__icon" />
                      )}
                    </Tooltip>

                  </Dropdown>
                )}
                {columnSelector && (
                  <Dropdown
                    list={list}
                    type="click"
                    dropDownPosition="bottom"
                    withArrow={true}
                    className="flex-v-center"
                    closeAfterSelect={false}
                    isMultiCheck={true}
                    dragDropProps={{
                      canDragAndDrop: canOrderColumn,
                      onDragEnd: onDragEnd
                    }}
                  >
                    <Tooltip content={'Add or remove columns'} position={'top'}>
                      <Icon icon="ColumnSelector" className="ColumnSelector__icon"></Icon>
                    </Tooltip>
                  </Dropdown>
                )}
              </div>
            </div>
          </div>
          : null}
      </div>
    </div>
  )
};

export const InfiniteScrollTable = (props: TableProps) => {
  //memoize data, column if needed
  const rowSelectCheckboxProp = props.rowSelectCheckboxProp

  const controlledSelectedRowIds =
    typeof props.initialSelectedRowIds === 'object'
      ? props.initialSelectedRowIds
      : defaultValue.controlledSelectedRowIds
  const name = props.name || defaultValue.name

  const columnSelector = typeof props.columnSelector === 'boolean' ? props.columnSelector : defaultValue.columnSelector
  const minBatchSizeToFetch = props.minBatchSizeToFetch || defaultValue.minBatchSizeToFetch
  const viewSelector = typeof props.viewSelector === 'boolean' ? props.viewSelector : defaultValue.viewSelector
  const loading = typeof props.loading === 'boolean' ? props.loading : defaultValue.loading
  const canSearch = typeof props.canSearch === 'boolean' ? props.canSearch : defaultValue.canSearch
  const canRefresh = typeof props.canRefresh === 'boolean' ? props.canRefresh : defaultValue.canRefresh
  const tableHeight = typeof props.tableHeight === 'number' ? props.tableHeight : 0
  const staticRowCount = typeof props.staticRowCount === 'number' ? props.staticRowCount : 0

  const totalCounts = typeof props.totalCounts === 'number' ? props.totalCounts : 30
  const maxSelect = typeof props.maxSelect === 'number' ? props.maxSelect : null
  const linkToRow = props.linkToRow || null
  const withExportCta = props.withExportCta || defaultValue.withExportCta
  const testId = typeof props.testId === 'string' ? props.testId : 'cs-table'

  const initialRowSelectedData: any = Array.isArray(props.initialRowSelectedData) ? props.initialRowSelectedData : []
  const hiddenColumnsProp: any = Array.isArray(props.hiddenColumns) ? props.hiddenColumns : null

  const rowDisableProp: RowDisableProp = props.rowDisableProp
  const onRowHoverText: any = props.onRowHoverText

  const canOrderColumn = typeof props.canOrderColumn === 'boolean' ? props.canOrderColumn : defaultValue.canOrderColumn
  const columnsOrder = props.columnsOrder || []
  const onChangeColumnOrder = props.onChangeColumnOrder

  let [selectedData, setSelectedData] = useState(initialRowSelectedData)
  const [viewBy, setViewBy] = useState(constants.comfort_view)
  const [searchValue, setSearchValue] = useState(props.searchValue || '')
  const [toolTipPosFromRight, updateTooltipPos] = useState(0)

  const itemSize = props.itemSize || (viewBy === constants.compact_view ? 30 : 60)

  const tableHeadRef = useRef(null)
  const tableRef = useRef(null)

  const tableHeadWdth = tableHeadRef.current && tableHeadRef.current.offsetWidth
  const tableWidth = tableRef.current && tableRef.current.offsetWidth
  const isTableWidthGreater = tableHeadWdth > tableWidth

  const defaultRef = useRef(null)
  const fixedlistRef = props.fixedlistRef || defaultRef

  const tableDefaultReducer = (newState) => newState

  const useTableInstance = useTable(
    {
      columns: props.columns,
      data: props.data,
      initialState: {
        hiddenColumns: props.hiddenColumns || [],
        sortBy: props.initialSortBy || [],
        selectedRowIds: controlledSelectedRowIds,
      },
      disableMultiSort: true,
      manualSortBy: true,
      disableSortRemove: true,
      manualPagination: true,
      autoResetSelectedRows: true,
      autoResetHiddenColumns: false,
      autoResetPage: false,
      getRowId: (row: any) => row[props.uniqueKey],
      stateReducer: props.tableStateReducer || tableDefaultReducer,
    },
    useSortBy,
    usePagination,
    useRowSelect,
    useColumnOrder,
    (hooks: any) => {
      if (props.isRowSelect) {
        pushCheckboxInRow(hooks, {
          rowSelectCheckboxProp,
          selectedData,
          maxSelect,
          totalCount: totalCounts,
          uniqueKey: props.uniqueKey,
          LinkComponent: props.LinkComponent,
          rowDisableProp,
        })
      }
    }
  )

  const {
    getTableProps,
    getTableBodyProps,
    allColumns,
    headerGroups,
    prepareRow,
    rows,
    state: { sortBy, selectedRowIds },
    setHiddenColumns,
    setColumnOrder,
  } = useTableInstance


  useEffect(() => {
    if (hiddenColumnsProp) {
      setHiddenColumns(hiddenColumnsProp)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [hiddenColumnsProp])

  useEffect(() => {
    let selectedRowIdsArr = Object.keys(selectedRowIds)
    if (rowSelectCheckboxProp) {
      selectedRowIdsArr = selectedRowIdsArr.filter((selectedRowId) => {
        const foundData = props.data.find((dataObj) => selectedRowId === dataObj.uid)
        //if not found means user is on different page or in case of asset user cliked on any folder
        if (!foundData) {
          return true
        }
        //props conditonal select check
        if (foundData && foundData[rowSelectCheckboxProp.key] === rowSelectCheckboxProp.value) {
          if (rowDisableProp) {
            if (foundData[rowDisableProp.key] === rowDisableProp.value) {
              return false
            }
          }
          return true
        }
        // ifconditon not match return false
        return false
      })
    }
    else if (rowDisableProp) {
      selectedRowIdsArr = selectedRowIdsArr.filter((selectedRowId) => {
        const foundData = props.data.find((dataObj) => selectedRowId === dataObj.uid)
        //if not found means user is on different page or in case of asset user cliked on any folder
        if (!foundData) {
          return true
        }
        //props conditonal disable check
        if (foundData[rowDisableProp.key] === rowDisableProp.value) {
          return false
        }
        return true
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps 
    selectedRowIdsArr.map((id) => {
      const foundData = props.data.find((data) => data[props.uniqueKey] === id)
      if (foundData) {
        selectedData.push(foundData)
      }
    })

    //remove duplicate
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    selectedData = selectedData.filter(
      (data, index, arr) => index === arr.findIndex((arrData) => arrData[props.uniqueKey] === data[props.uniqueKey])
    )

    //filter out unselected data
    selectedData = selectedData.filter((data) => selectedRowIdsArr.includes(data[props.uniqueKey]))
    setSelectedData(selectedData)

    if (props.getSelectedRow) {
      // Passing allRow Selected Data as second Argument
      props.getSelectedRow(selectedRowIdsArr, selectedData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [Object.keys(selectedRowIds).length, props.data.length])

  useEffect(() => {
    const fetchDataArgument: FetchDataArgProp = defaultValue.fetchTableDataArg
    if (sortBy.length) {
      const { desc, id } = sortBy[0]
      const sortingDirection = desc ? constants.desc : constants.asc
      fetchDataArgument.sortBy = { sortingDirection, id }
    }
    fetchDataArgument.searchText = searchValue
    if (fixedlistRef && fixedlistRef.current) {
      fixedlistRef.current.scrollToItem(0)
    }
    if (!props.resetSearch) {
      props.fetchTableData(fetchDataArgument)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [sortBy, searchValue])

  useEffect(() => {
    if (props.resetSearch) {
      setSearchValue('')
    }
  }, [props.resetSearch])

  useEffect(() => {
    const posFromRight = tableHeadWdth + defaultValue.toolTipOffset - tableWidth
    updateTooltipPos(posFromRight)
  }, [tableWidth, tableHeadWdth])

  useEffect(() => {
    setColumnOrder(columnsOrder)
  }, [columnsOrder.join(',')])

  const setTableColumnOrder = (updatedColumnOrder) => {
    setColumnOrder(updatedColumnOrder)
    if (onChangeColumnOrder) {
      onChangeColumnOrder(updatedColumnOrder)
    }
  }

  const handleRowClick = (e, isRowDisabled, row) => {
    if (props.onRowClick && e.target.id !== 'rowSelect' && !isRowDisabled) {
      props.onRowClick(row.original)
    }

    if (props.isRowSelect && props.fullRowSelect) {
      const disableSelect = maxSelect && selectedData.length >= maxSelect && row.original && !selectedData.find((data) => data[props.uniqueKey] === row.original[props.uniqueKey])
      if (rowSelectCheckboxProp && row.original[rowSelectCheckboxProp.key] === rowSelectCheckboxProp.value && !disableSelect) {
        const isSelected = row.isSelected ? false : true
        row.toggleRowSelected(isSelected)
      } else if (!rowSelectCheckboxProp && !disableSelect) {
        const isSelected = row.isSelected ? false : true
        row.toggleRowSelected(isSelected)
      }
    }
  }


  /**
   * It renders each row data
   * If data is avaibale and not loading
   * And on loading it shows skeleton loader for that row
   **/
  const RenderRow = ({ index, style }) => {
    const row = rows[index]
    if (index >= totalCounts) {
      return null
    }

    const RenderCell = () => {
      return row.cells.map((cell: any, i) => {
        if (cell.column.id === 'selection') {
          return (
            <div {...cell.getCellProps()} className="Table-select-body flex-v-center">
              {cell.render('Cell')}
            </div>
          )
        } else {
          return (
            <div
              {...cell.getCellProps()}
              className={
                'Table__body__column ' +
                (cell.column.cssClass ? cell.column.cssClass + ' ' : '') +
                (cell.column.isSorted ? 'Table__body__column--sorted ' : '') +
                (viewBy === 'Compact' ? 'Table__body__column--compact ' : '')
              }
              style={!props.equalWidthColumns ? {
                ...(cell.column.columnWidthMultiplier
                  ? { width: `${(cell.column.columnWidthMultiplier * defaultValue.baseColumnWidth) / 16}rem` }
                  : {}),
              } : {}}
              key={cell.column.id + i}
            >
              {cell.render('Cell')}
            </div>
          )
        }
      })
    }

    if (props.itemStatusMap[index] === 'loaded' && row) {
      prepareRow(row)
      const isSingleRowSelected = !props.isRowSelect && row.id === props.singleSelectedRowId
      const onHoverListCondition = props.onHoverListCondition

      let isRowDisabled = false;
      let disabledMessage;
      if (props.conditionalSingleSelect) {
        isRowDisabled = row.original[props.conditionalSingleSelect.key] === props.conditionalSingleSelect.value
        disabledMessage = props.conditionalSingleSelect.message
      } else if (rowDisableProp) {
        isRowDisabled = row.original[rowDisableProp.key] === rowDisableProp.value
      }

      const actionList = onHoverListCondition
        ? row.original[onHoverListCondition.key]
          ? onHoverListCondition.truthyList
          : onHoverListCondition.falsyList
        : props.onHoverActionList

      const TableRow = () => (
        <div
          {...row.getRowProps()}
          className={getTableRowClassString(row, isRowDisabled, rowSelectCheckboxProp, viewBy, isSingleRowSelected, props.equalWidthColumns, rowDisableProp)}
          onClick={(e) => handleRowClick(e, isRowDisabled, row)}
          style={style}
          key={row.id}
        >
          {actionList ? (
            <ActionTooltip list={actionList} data={row.original} right={toolTipPosFromRight}>
              <RenderCell />
            </ActionTooltip>
          ) : isSingleRowSelected ? (
            <SingleRowSelectedUi>
              <RenderCell />
            </SingleRowSelectedUi>
          ) : (
            disabledMessage && isRowDisabled ?
              (
                <Tooltip content={disabledMessage} position="top" showArrow={false}>
                  <RenderCell />
                </Tooltip>
              ) :
              <RenderCell />
          )}
        </div>
      )

      const handleTableLinkRowClick = (e: any) => {
        if (e.target.id === "rowSelect") {
          e.stopPropagation();
          e.preventDefault();
        }
      }

      const linkRowCheck = !(props.fullRowSelect || typeof props.singleSelectedRowId === 'string') && linkToRow
      const { LinkComponent } = props;
      let hoverText = ''
      if (onRowHoverText) {
        hoverText = onRowHoverText(row.original)
      }

      if (props.LinkComponent) {
        return (
          <div title={hoverText}>
            {linkRowCheck ? (
              <LinkComponent
                onClick={handleTableLinkRowClick}
                tabIndex={-1}
                to={typeof linkToRow === "function" ? linkToRow(row.original) : linkToRow}
              >
                <TableRow />
              </LinkComponent>
            ) : (
              <TableRow />
            )}
          </div>
        );
      }
      return (
        <div title={hoverText}>
          {linkRowCheck ? (
            <a onClick={handleTableLinkRowClick} tabIndex={-1} href={typeof linkToRow === "function" ? linkToRow(row.original) : linkToRow}>
              <TableRow />
            </a>
          ) : (
            <TableRow />
          )}
        </div>
      );
    }

    if (props.itemStatusMap[index] === 'loading') {
      return (
        <div className={'Table__body__row ' + (viewBy === 'Compact' ? 'Table__body__row--compact ' : '') + (props.equalWidthColumns ? 'Table__body__row--equal-width-columns' : '')} style={style}>
          {Array.isArray(headerGroups) && headerGroups.length > 0 && headerGroups[0].headers.map((column: any, loadingIndex) => {
            if (column.id === 'selection') {
              return (
                <div key={loadingIndex} className="Table-select-body flex-v-center ml-22">
                  <div className="skeleton-square"></div>
                </div>
              )
            }
            return (
              <div
                key={loadingIndex}
                className={'Table__body__column flex-v-center ' + (column.cssClass ? column.cssClass : '')}
                style={!props.equalWidthColumns ? {
                  ...(column.columnWidthMultiplier && !props.equalWidthColumns ? { width: `${(column.columnWidthMultiplier * 140) / 16}rem` } : {}),
                } : {}}
              >
                <SkeletonTile
                  numberOfTiles={1}
                  tileHeight={10}
                  tileWidth={80}
                  tileBottomSpace={7}
                  tileTopSpace={5}
                  tileleftSpace={5}
                />
              </div>
            )
          })}
        </div>
      )
    }
    return null
  }

  /**
   * It renders table header data
   * And handle sorting of column
   **/
  const TableHead = () => {
    return (
      <div className={`Table__head ${isTableWidthGreater ? 'Table__head--scroll ' : ''} ${viewBy === 'Compact' ? 'Table__head--compact' : ''}`} ref={tableHeadRef}>
        {headerGroups.map((headerGroup: any) => (
          <div {...headerGroup.getHeaderGroupProps()} className={`Table__head__row ${props.equalWidthColumns ? 'Table__head__row--equal-width-columns' : ''}`}>
            {headerGroup.headers.map((column: any) => {
              if (column.id === 'selection') {
                return (
                  <div
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="Table-select-head flex-v-center"
                  >
                    {column.render('Header')}
                  </div>
                )
              }
              return (
                <div
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={`Table__head__column ${column.cssClass || ''} ${column.isSorted ? 'Table__head__cell--sorted' : ''}`}
                  style={!props.equalWidthColumns ? {
                    ...(column.columnWidthMultiplier
                      ? { width: `${(column.columnWidthMultiplier * defaultValue.baseColumnWidth) / 16}rem` }
                      : {}),
                  } : {}}
                >
                  <div>
                    <span className='Table__head__column-text'>
                      {column.render('Header')}
                    </span>
                    {!column.disableSortBy && (
                      <span className="sorting-wrapper">
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <Icon icon="DownArrowEnabled" size="small" hover={false} />
                          ) : (
                            <Icon icon="DownArrowEnabled" size="small" hover={false} className="table-header-sort-asc" />
                          )
                        ) : (
                          <Icon icon="DownArrowEnabled" size="small" hover={false} />
                        )}
                      </span>
                    )}
                  </div>
                </div>
              )
            })}
          </div>
        ))}
      </div>
    )
  }

  const updateViewBy = (selectedViewBy) => {
    if (viewBy !== selectedViewBy) {
      setViewBy(selectedViewBy)

      if (props.getViewByValue) {
        props.getViewByValue(selectedViewBy)
      }
    }
  }

  const dropDownList = [
    {
      label: (
        <>
          <Icon icon="ComfortView" />
          <div>{constants.comfort_view}</div>
        </>
      ),
      action: () => updateViewBy(constants.comfort_view),
      default: true,
    },
    {
      label: (
        <>
          <Icon icon="CompactView" />
          <div>{constants.compact_view}</div>
        </>
      ),
      action: () => updateViewBy(constants.compact_view),
    },
  ]

  const loadMoreItems = (startIndex, stopIndex) => {
    /**
     * Dont make call initially for loading case (totalCount not a number)
     * let parent component make call through fetchTabelData on mount or
     * if sortBy changes table will call fetch table data
     **/
    if (typeof props.totalCounts !== 'number') {
      return
    }
    const skip = startIndex ? startIndex - 1 : startIndex
    const limit = stopIndex - skip

    const fetchDataArgument: any = { skip, limit, startIndex, stopIndex }

    if (sortBy.length) {
      const { desc, id } = sortBy[0]
      const sortingDirection = desc ? constants.desc : constants.asc
      fetchDataArgument.sortBy = { sortingDirection, id }
    }

    if (searchValue) {
      fetchDataArgument.searchText = searchValue
    }

    props.loadMoreItems(fetchDataArgument)
  }

  const fetchMoreItems = loading ? () => { } : loadMoreItems

  const onScroll = (scrolData) => {
    if (props.getScrollPostion) {
      props.getScrollPostion(scrolData.scrollOffset)
    }
  }

  const onChangeSearch = (value) => {
    setSearchValue(value)

  }

  const onRefresh = () => {
    const fetchDataArgument: FetchDataArgProp = defaultValue.fetchTableDataArg
    if (sortBy.length) {
      const { desc, id } = sortBy[0]
      const sortingDirection = desc ? constants.desc : constants.asc
      fetchDataArgument.sortBy = { sortingDirection, id }
    }
    fetchDataArgument.searchText = searchValue
    if (fixedlistRef && fixedlistRef.current) {
      fixedlistRef.current.scrollToItem(0)
    }
    props.fetchTableData(fetchDataArgument)
  }

  const updateToolTipPosition = () => {
    const scrollLeft = (tableRef.current && tableRef.current.scrollLeft) || 0
    const toolTipPosFromRight = tableHeadWdth + defaultValue.toolTipOffset - (tableWidth + scrollLeft)
    updateTooltipPos(toolTipPosFromRight)
  }

  const isItemLoaded = (index) => {
    if (props.itemStatusMap[index] === 'loaded') {
      return true
    }
    return false
  }


  const itemCount = props.data.length < totalCounts ? props.data.length + minBatchSizeToFetch : props.data.length
  const finalCount = itemCount >= totalCounts ? totalCounts : itemCount

  const emptyHead = props.emptyHeading || 'No Records Found'
  const emptyDesc = props.emptyDescription || ''
  return (
    <div data-test-id={testId}>
      {selectedData.length && props.onRowSelectProp && props.onRowSelectProp.length ? (
        <RowSelectAction
          selectedData={selectedData}
          name={name}
          onRowSelectProp={props.onRowSelectProp}
        />
      ) : props.hideTablePanel === true ? null : (
        <TablePanel
          staticRowCount={staticRowCount}
          columnSelector={columnSelector}
          allColumns={allColumns}
          name={name}
          totalCounts={totalCounts}
          viewSelector={viewSelector}
          dropDownList={dropDownList}
          viewBy={viewBy}
          loading={loading}
          onChangeSearch={onChangeSearch}
          onRefresh={onRefresh}
          searchPlaceholder={props.searchPlaceholder}
          searchValue={props.searchValue}
          canSearch={canSearch}
          canRefresh={canRefresh}
          onToggleColumnSelector={props.onToggleColumnSelector}
          withExportCta={withExportCta}
          setTableColumnOrder={setTableColumnOrder}
          canOrderColumn={canOrderColumn}
        />
      )}
      <div {...getTableProps()} className="Table" ref={tableRef} onScroll={updateToolTipPosition}>
        <div {...getTableBodyProps()}>
          {totalCounts === 0 && !loading ? (
            <EmptyState
              heading={(props.emptyObj && props.emptyObj.heading) || emptyHead}
              description={(props.emptyObj && props.emptyObj.description) || emptyDesc}
              displayImage={false}
              type="primary"
              actions={props.emptyObj && props.emptyObj.actions}
              moduleIcon={props.emptyObj && props.emptyObj.moduleIcon}
              forPage={props.emptyObj && props.emptyObj.forPage}
            />
          ) : (
            <div style={{ height: tableHeight || '100vh' }}>
              <InfiniteLoader
                isItemLoaded={isItemLoaded}
                itemCount={finalCount + 1}
                loadMoreItems={fetchMoreItems}
                minimumBatchSize={minBatchSizeToFetch}
                threshold={Math.floor(minBatchSizeToFetch / 2)}
              >
                {({ onItemsRendered, ref }: any) => (
                  <AutoSizer disableWidth={true} >
                    {({ height }) => {
                      const TABLE_HEAD_HEIGHT = 40;

                      return (
                        <>
                          <TableHead />
                          {props.customRowAdd && props.customRowComponent}
                          <FixedSizeList
                            className={`Table__body ${isTableWidthGreater ? 'Table__body--scroll ' : ''
                              } ${!isItemLoaded ? 'Table__body--loading' : ''}`}
                            width="100%"
                            height={height - TABLE_HEAD_HEIGHT}
                            itemCount={finalCount}
                            itemSize={itemSize}
                            onItemsRendered={onItemsRendered}
                            ref={(list) => {
                              ref(list);
                              fixedlistRef.current = list;
                            }}
                          >
                            {RenderRow}
                          </FixedSizeList>
                        </>
                      )
                    }}
                  </AutoSizer>
                )}
              </InfiniteLoader>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default withDeprecatedProp(InfiniteScrollTable, { serachValue: "searchValue" })
