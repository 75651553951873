import React, { Component, PureComponent } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import _ from 'lodash';
import { components } from 'react-select'

class MultiValueContainers extends Component<any, any>{


  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps && this.props) {
      if (nextProps.children && this.props.children && nextProps.children.length !== this.props.children) {
        return true
      }
      if (nextProps.data && this.props.data && !_.isEqual(nextProps.data, this.props.data)) {
        return true
      }
      if (nextProps.innerProps && this.props.innerProps && !_.isEqual(nextProps.innerProps, this.props.innerProps)) {
        return true
      }
      if (nextProps.selectProps && this.props.selectProps && !_.isEqual(nextProps.selectProps, this.props.selectProps)) {
        return true
      }
    }
    return false;
  }

  render() {

    const { children } = this.props;

    const newChildren = cloneDeep(children);
    const nbValues = this.props.selectProps.value.length;
    newChildren[0] = `${nbValues} ${this.props.selectProps.selectedLabel || 'items selected'}`;

    if (nbValues < 2) {
      return (
        <div className="multi-value-wrapper">
          <components.MultiValueContainer {...this.props}>
            {children}
          </components.MultiValueContainer>
        </div>
      );
    }
    return (
      <div className="multi-value-wrapper">
        <components.MultiValueContainer {...this.props}>
          {<>
            <span className="multi-value-count">{newChildren[0]}</span>
            <span className="multi-value-list">{children}</span>
          </>}
        </components.MultiValueContainer>
      </div>
    );
  }
}

export default MultiValueContainers