import React, { useState } from 'react';

import withDeprecatedProp from '../../utils/hooks/depricatedPropsHoc';
import Button from '../Button/Button';
import Datepicker from '../Datepicker/Datepicker';
import TimePicker2 from '../Timepicker/TimePicker2';

import './DateTimepicker.css';

interface IDateTimepicker {
  initialDate?: string
  formatType?: string
  startDate?: Date
  endDate?: Date
  onDone?: (data?: any) => void
  onCancel?: (data?: any) => void
  onCloseModal?: () => void
  hideTime?: boolean
  testId?: string
}

const DateTimepicker2 = (props: IDateTimepicker) => {
  const { initialDate, onDone, onCancel, onCloseModal, hideTime, testId, startDate, endDate } = props
  const initDate = initialDate
  const [dateVal, setDateVal] = useState(initialDate)
  const [timeVal, setTimeVal] = useState(initialDate)
  const [initialDate2] = useState(initialDate)
  let initialTime: string
  if (initialDate) {
    initialTime = `${new Date(initialDate).toLocaleTimeString()}${new Date(initialDate).toString().split('GMT')[1].split(' (')[0]
      }`
  }

  const [initialTime2] = useState(initialTime);

  const doneHandler = () => {
    onDone({
      date: dateVal,
      time: timeVal
    });
    if (onCloseModal) {
      onCloseModal();
    }
  };

  const cancelHandler = () => {
    onCancel(initDate);
    if (onCloseModal) {
      onCloseModal();
    }
  };

  const timeChangeHandler = (value: any) => {
    setTimeVal(value);
  }

  const dateChangeHandler = (value: any) => {
    setDateVal(value);
  }

  return (
    <div className="DateTimePicker" data-test-id={testId}>
      <Datepicker initialDate={initialDate2} startDate={startDate} endDate={endDate} onChange={dateChangeHandler} viewType="datetime" />
      {hideTime ? null : (
        <TimePicker2
          initialDate={initialTime2}
          inputDisabled={false}
          placeholder="Select time"
          viewType="datetime"
          onChange={timeChangeHandler}
        />
      )}
      <div className="DateTimePicker__actions flex-right">
        <Button buttonType="primary" onClick={doneHandler}>Done</Button>
        <Button buttonType="tertiary" onClick={cancelHandler}>Cancel</Button>
      </div>
    </div>
  )
};

DateTimepicker2.defaultProps = {
  testId: 'cs-datetimepicker'
}
export const DateTimePicker2 = withDeprecatedProp(DateTimepicker2, { 'closeModal': 'onCloseModal' });
export default withDeprecatedProp(DateTimepicker2, { 'closeModal': 'onCloseModal' }, { 'DateTimepicker2': 'DateTimePicker2' })
