import React, { useEffect, useState } from 'react'
import { components } from 'react-select'
import { AsyncPaginate, wrapMenuList } from 'react-select-async-paginate'

import Checkbox from '../Checkbox/Checkbox'
import ContentEditor from './ContentEditor'
import FieldLabel from '../FieldLabel/FieldLabel'
import SkeletonTile from '../SkeletonTile/SkeletonTile'
// import Button from '../Button/Button'

// const CustomOption = ({ cx, children, getStyles, innerRef, ...props }) => (
//   <div
//     ref={innerRef}
//     className={cx(
//       css(getStyles('option', props)),
//       {
//         'option': true,
//         'option--is-disabled': isDisabled,
//         'option--is-focused': isFocused,
//         'option--is-selected': isSelected,
//       }
//     )}
//   >
//     {children}
//   </div>
// )

// const ValueContainer = (props) => {
//   const options = props.selectProps.value && props.selectProps.value.length
//   return (
//     <components.ValueContainer {...props}>
//       {options > 2 ? (
//         <span ref={props.innerRef} {...props.innerProps} >{`${options} ${
//           options === 1 ? ' option' : ' options'
//           }  selected`}</span>
//       ) : (
//           props.children
//         )}
//     </components.ValueContainer>
//   )
// }

// const customStyles = {
//   multiValueLabel: (provided: any, state: any) => ({
//     ...provided,
//     fontSize: '12px',
//   }),
//   singleValue: (provided: any, state: any) => ({
//     ...provided,
//     fontSize: '12px',
//   }),
// }

const SingleValue = props => {
  const options = props.getValue()
  const { updateOption, ...rest } = props
  return (
    <components.SingleValue {...rest}>
      {options.map((option: any, index: number) => {
        return (
          <ContentEditor
            key={index}
            value={option.label}
            option={option}
            updateOption={updateOption}
          />
        )
      })}
    </components.SingleValue>
  )
}

const MultiValueLabel = (props: any) => {
  const { updateOption, ...rest } = props
  return (
    <components.MultiValueLabel {...rest}>
      <ContentEditor value={rest.data.label} option={rest.data} updateOption={updateOption} />
    </components.MultiValueLabel>
  )
}

const SkeletonLoader = ({ size = 2, isMulti }) => {
  return (
    <div>
      {Array(size)
        .fill(1)
        .map((data, index) => {
          return (
            <div className="flex-v-center" key={index}>
              {isMulti && <SkeletonTile
                numberOfTiles={1}
                tileHeight={16}
                tileWidth={16}
                tileRadius={4}
                tileBottomSpace={10}
                tileTopSpace={10}
                tileleftSpace={20}
              />}
              <SkeletonTile
                numberOfTiles={1}
                tileHeight={12}
                tileWidth={isMulti ? 105 : 145}
                tileRadius={6}
                tileBottomSpace={12}
                tileTopSpace={12}
                tileleftSpace={isMulti ? 12 : 20}
              />
            </div>
          )
        })}
    </div>
  )
}

const MenuList = wrapMenuList((props: any) => {
  return (
    <components.MenuList {...props}>
      {props.children}
      {props.isLoading && <SkeletonLoader size={2} isMulti={props.isMulti} />}
    </components.MenuList>
  )
})

const Option = (props: any) => {
  if (props.isMulti) {
    return (
      <components.Option {...props}>
        <Checkbox checked={props.isSelected} text={props.children} fullWidth={true} />
        {/* {props.children} */}
      </components.Option>
    )
  }
  return <components.Option {...props} />
}

const customComponents = ({ updateOption, canEditOption }: any) => {
  if (canEditOption) {
    return {
      SingleValue: (props: any) => <SingleValue {...props} updateOption={updateOption} />,
      MultiValueLabel: (props: any) => <MultiValueLabel {...props} updateOption={updateOption} />,
      Option,
      MenuList,
    }
  } else {
    return {
      Option,
      MenuList,
    }
  }
}

type LoadMoreArgsProps = {
  search: string
  skip: number
  limit: number
  prevOptions: any
}

type InitArrayProps = {
  label: String,
  value: String
}
type LoadMoreOptReturnProps = {
  data: any,
  hasMore: boolean
}

export type SelectProps = {
  width: string
  maxWidth?: string
  minWidth?: string
  name?: string
  isDisabled?: boolean
  isClearable?: boolean
  isMulti?: boolean
  isSearchable?: boolean
  placeholder?: string
  hideSelectedOptions?: boolean
  menuShouldScrollIntoView?: boolean
  menuPlacement?: 'auto' | 'bottom' | 'top'
  menuIsOpen?: boolean
  maxMenuHeight?: number
  minMenuHeight?: number
  onChange: Function
  onBlur?: Function
  value: any
  canEditOption?: boolean
  selectLabel?: string
  updateOption?: (UpdateOptionProps: { label: any; id: any }) => void
  limit?: number
  loadMoreOptions: (loadmoreArgs: LoadMoreArgsProps) => LoadMoreOptReturnProps
  // totalCounts: number
  initialOptions?: Array<InitArrayProps>
  defaultOptions?: boolean
  error?: boolean
  debounceTimeout?: number
}

const AsyncSelectBox: React.FunctionComponent<SelectProps> = props => {
  let limit = props.limit || 10


  const loadOptions = async (search: any, prevOptions: any) => {
    try {
      console.log('calling loadOptions')

      let skip = prevOptions.length
      const response: any = await props.loadMoreOptions({ search: search || '', skip, limit, prevOptions })
      return {
        options: response.options,
        hasMore: response.hasMore
      }
    } catch (error) {
      console.log('loadOptions error', error)
    }
  }

  const allComponents = customComponents({
    updateOption: props.updateOption,
    canEditOption: props.canEditOption,
  })


  let widthStyleObj: any = {}
  if (props.width) {
    widthStyleObj = { width: props.width }
  } else {
    widthStyleObj = { maxWidth: props.maxWidth || '500px', minWidth: props.minWidth || '200px' }
  }


  return (
    <div
      className={`Select ${props.error ? 'Select--error' : ''}`}
      style={{ ...widthStyleObj }}
    >
      {props.selectLabel &&
        <FieldLabel htmlFor="selectLabel">
          {props.selectLabel}
        </FieldLabel>
      }
      <AsyncPaginate
        name={props.name}
        closeMenuOnSelect={!props.isMulti}
        isDisabled={props.isDisabled || false}
        isClearable={props.isClearable || false}
        isMulti={props.isMulti || false}
        isSearchable={props.isSearchable || false}
        placeholder={props.placeholder || 'Select ...'}
        hideSelectedOptions={props.hideSelectedOptions || false}
        closeMenuOnScroll={true}
        menuShouldBlockScroll={false}
        menuShouldScrollIntoView={props.menuShouldScrollIntoView}
        menuPlacement={props.menuPlacement}
        menuIsOpen={props.menuIsOpen}
        maxMenuHeight={props.maxMenuHeight || 200}
        minMenuHeight={props.minMenuHeight}
        onChange={props.onChange}
        // onInputChange={handleInputChange}
        onBlur={props.onBlur}
        value={props.value}
        components={allComponents}
        backspaceRemovesValue={props.canEditOption ? false : true}
        loadOptions={loadOptions}
        defaultOptions={typeof props.defaultOptions === 'boolean' ? props.defaultOptions : true}
        classNamePrefix="Select"
        loadingMessage={() => ''}
        options={props.initialOptions}
        aria-label={'cs-async-select-aria'}
        debounceTimeout={props.debounceTimeout}
      />
    </div>
  )
}

export default React.memo(AsyncSelectBox)

// ====== v1 buggy
// const SelectBox = (props) => {
//   // console.log("SelectBox -> props", props)

//   let limit = props.limit || 10
//   let [skip, setSkip] = useState(0)
//   let [hasMore, setHasMore] = useState(true)
//   let [inputValue, setInputValue] = useState('')

//   const loadMoreOptions = async () => {
//     try {
//       if (props.options.length >= props.totalCounts) {
//         setHasMore(false)
//         return
//       }
//       const updatedSkip = skip + limit
//       setSkip(updatedSkip)
//       props.loadMoreOptions({ inputValue, skip: updatedSkip, limit })
//     } catch (error) {
//       console.log('error', error)
//     }
//   }

//   const handleInputChange = (newValue: string) => {
//     const inputValue = newValue.replace(/\W/g, '')
//     setInputValue(inputValue)
//     setSkip(0)
//     setHasMore(true)
//     return inputValue
//   }

//   const promiseOptions = (inputValue) => {
//     return props.loadOptions({ inputValue, skip, limit })
//   }

//   return (
//     <div
//       style={{
//         width: props.width,
//         maxWidth: props.maxWidth || '300px',
//         minWidth: '150px',
//       }}
//     >
//       <AsyncSelect
//         name={props.name}
//         closeMenuOnSelect={!props.isMulti}
//         isDisabled={props.isDisabled || false}
//         isClearable={props.isClearable || false}
//         isMulti={props.isMulti || false}
//         isSearchable={props.isSearchable || false}
//         placeholder={props.placeholder || 'Select ...'}
//         hideSelectedOptions={props.hideSelectedOptions || false}
//         closeMenuOnScroll={true}
//         menuShouldBlockScroll={false}
//         menuShouldScrollIntoView={props.menuShouldScrollIntoView}
//         menuPlacement={props.menuPlacement}
//         // menuIsOpen={props.menuIsOpen}
//         menuIsOpen={true}
//         // maxMenuHeight={props.maxMenuHeight || 200}
//         // minMenuHeight={props.minMenuHeight}
//         onChange={(newValue) =>
//           props.onChange(props.isMulti ? newValue || [] : newValue)
//         }
//         onBlur={props.onBlur}
//         // options={props.options}
//         value={props.value}
//         // className={'classname'}
//         // classNamePrefix="select"
//         styles={customStyles}
//         components={customComponents({
//           updateOption: props.updateOption,
//           canEditOption: props.canEditOption,
//           loadMoreOptions: loadMoreOptions,
//           loading: props.loading,
//           hasMore,

//         })}
//         backspaceRemovesValue={props.canEditOption ? false : true}
//         defaultOptions={props.options}
//         cacheOptions
//         loadOptions={promiseOptions}
//         onInputChange={handleInputChange}
//         isLoading={props.loading || false}
//       />
//     </div>
//   )
// }

// const MenuList = props => {
//   console.log(" MenuList props", props)

//   const { loadMoreOptions, hasMore, ...rest } = props;
//   return (

//     <components.MenuList {...rest}>
//       <InfiniteScroll
//         dataLength={props.options.length}
//         next={loadMoreOptions}
//         hasMore={hasMore}
//         loader={<h4 style={{ textAlign: 'center' }}>Loading...</h4>}
//         // scrollableTarget="scrollableDivSelect"
//         height={200}
//         hasChildren={true}
//         endMessage={
//           <p style={{ textAlign: 'center' }}>
//             <b>- end -</b>
//           </p>
//         }
//       >
//         {props.children}
//       </InfiniteScroll>
//     </components.MenuList>

//   );
// };

// v1 implementation
// export const AsyncSelect = () => {
//   let pageSize = 10

//   const [options, setOpts] = useState(data.slice(0, pageSize))

//   const [value, updateValue] = useState(null)
//   const [totalCount, updtaeTotalCount] = useState(0)
//   const [loading, setLoading] = useState(false)

//   const handleValueUpdate = (data) => {
//     updateValue(data)
//   }

//   const loadOptions = async ({ inputValue, skip, limit }) => {
//     console.log('loadOptions', skip, limit)
//     setLoading(true)
//     const response = await serveData({ inputValue, skip, limit })
//     setLoading(false)
//     console.log('loadOptions -> response', response)
//     updtaeTotalCount(response.count)
//     return response.data
//   }

//   const handleLoadMoreOptions = async ({ inputValue, skip, limit }) => {
//     console.log('handleLoadMoreOptions -> inputValue, skip, limit', {
//       inputValue,
//       skip,
//       limit,
//     })
//     setLoading(true)
//     const response = await serveData({ inputValue, skip, limit })
//     console.log('handleLoadMoreOptions -> response', response)
//     updtaeTotalCount(response.count)
//     setOpts([...options, ...response.data])
//     setLoading(false)
//   }

//   return (
//     <div>
//       <label>async select load more and search example</label>
//       <AsyncSelectBox
//         value={value}
//         onChange={handleValueUpdate}
//         // options={data}
//         placeholder="Select value"
//         loadOptions={loadOptions}
//         loadMoreOptions={handleLoadMoreOptions}
//         isSearchable={true}
//         // isMulti
//         limit={pageSize}
//         options={options}
//         width="300px"
//         totalCount={totalCount}
//         loading={loading}
//       />
//     </div>
//   )
// }

//=====v0
// const SelectBox = (props) => {

//   let pageSize = props.pageSize || 10
//   let pageCount = props.pageCount || 1

//   let [pageIndex, setPageIndex] = useState(0)
//   let [disabled, setDisabled] = useState(false)
//   let [inputValue, setInputValue] = useState('')

//   const handleLoadMoreOptions = () => {
//     let currentPageIndex = pageIndex + 1
//     setPageIndex(currentPageIndex)

//     if (currentPageIndex > pageCount) {
//       setDisabled(true)
//     } else {
//       const skip = currentPageIndex * pageSize
//       const limit = pageSize
//       props.loadMoreOptions({ inputValue, skip, limit })
//     }
//   }

//   const handleInputChange = (newValue: string) => {
//     const inputValue = newValue.replace(/\W/g, '')
//     setInputValue(inputValue)
//     setPageIndex(0)
//     setDisabled(false)
//     return inputValue
//   }

//   const promiseOptions = (inputValue) => {
//     const skip = pageIndex * pageSize
//     const limit = pageSize
//     return props.loadOptions({ inputValue, skip, limit })
//   }

//   return (
//     <div
//       style={{
//         width: props.width,
//         maxWidth: props.maxWidth || '300px',
//         minWidth: '150px',
//       }}
//     >
//       <AsyncSelect
//         name={props.name}
//         closeMenuOnSelect={!props.isMulti}
//         isDisabled={props.isDisabled || false}
//         isClearable={props.isClearable || false}
//         isMulti={props.isMulti || false}
//         isSearchable={props.isSearchable || false}
//         placeholder={props.placeholder || 'Select ...'}
//         hideSelectedOptions={props.hideSelectedOptions || false}
//         closeMenuOnScroll={true}
//         menuShouldBlockScroll={false}
//         menuShouldScrollIntoView={props.menuShouldScrollIntoView}
//         menuPlacement={props.menuPlacement}
//         menuIsOpen={props.menuIsOpen}
//         maxMenuHeight={props.maxMenuHeight || 200}
//         minMenuHeight={props.minMenuHeight}
//         onChange={(newValue) =>
//           props.onChange(props.isMulti ? newValue || [] : newValue)
//         }
//         onBlur={props.onBlur}
//         // options={props.options}
//         value={props.value}
//         // className={'classname'}
//         // classNamePrefix="select"
//         styles={customStyles}
//         components={customComponents({
//           updateOption: props.updateOption,
//           canEditOption: props.canEditOption,
//           loadMoreOptions: handleLoadMoreOptions,
//           disabled: disabled || props.isLoading,
//         })}
//         backspaceRemovesValue={props.canEditOption ? false : true}
//         defaultOptions={props.defaultOptions}
//         cacheOptions
//         loadOptions={promiseOptions}
//         onInputChange={handleInputChange}
//         isLoading={props.isLoading || false}
//       />
//     </div>
//   )
// }

// const Menu = (props) => {
//   const { loadMoreOptions, disabled, ...rest } = props
//   return (
//     <>
//       <components.Menu {...rest}>
//         {rest.children}
//         <div style={menuHeaderStyle}>
//           <button disabled={disabled} onClick={loadMoreOptions}>
//             Load more
//           </button>
//         </div>
//       </components.Menu>
//     </>
//   )
// }

// v0 implementation
// export const AsyncSelect = () => {
//   const [initOpts, setOpts] = useState(data.slice(0, 10))
//   const [value1, updateValue1] = useState(null)
//   const [pageCount, setPageCount] = useState(0)
//   const [isLoading, setIsLoading] = useState(false)

//   const handleValue1Update = (data) => {
//     updateValue1(data)
//   }

//   const updatePageCount = (count) => {
//     const pageCount = Math.ceil(count / 10)
//     setPageCount(pageCount)
//   }

//   const promiseOptions = async ({ inputValue, skip, limit }) => {
//     const response = await serveData({ inputValue, skip, limit })
//     console.log('promiseOptions -> response', response)
//     updatePageCount(response.count)
//     return response.data
//   }

//   const handleLoadMoreOptions = async ({ inputValue, skip, limit }) => {
//     console.log('handleLoadMoreOptions -> inputValue, skip, limit', {
//       inputValue,
//       skip,
//       limit,
//     })
//     setIsLoading(true)
//     const response = await serveData({ inputValue, skip, limit })
//     // console.log('handleLoadMoreOptions -> response', response)
//     updatePageCount(response.count)
//     setOpts([...initOpts, ...response.data])
//     setIsLoading(false)
//   }

//   return (
//     <div>
//       <label>async select load more and search example</label>
//       <AsyncSelectBox
//         value={value1}
//         onChange={handleValue1Update}
//         // options={data}
//         placeholder="Select value"
//         loadOptions={promiseOptions}
//         loadMoreOptions={handleLoadMoreOptions}
//         isSearchable={true}
//         // isMulti
//         defaultOptions={initOpts}
//         width="300px"
//         pageCount={pageCount}
//         isLoading={isLoading}
//       />
//     </div>
//   )
// }

//shouldLoadMore
// const shouldLoadMore = (scrollHeight: number, clientHeight: number, scrollTop: number) => {

//   const sum = clientHeight + scrollTop + 20
//   if (sum > scrollHeight) {
//     console.log('shouldLoadMore -> true')
//     return true
//   }
//   console.log('shouldLoadMore -> false')
//   return false
// }
