import React, { useEffect, useRef, useState } from 'react'
import _ from 'lodash';

import './ContentEditor.css'

// const setCaretPos = (input_node, pos) => {
//   if (input_node.setSelectionRange) {
//     input_node.setSelectionRange(pos, pos)
//   } else if (input_node.createTextRange) {
//     input_node.createTextRange().move('character', pos)
//   }
//   input_node.focus()
// }

const areEqual = (prevProps, nextProps) => {
  return _.isEqual(prevProps, nextProps);
}

const autoSize = (node) => {
  let mirror = document.querySelector('.Tag__item__mirror')
  if (!mirror) {
    mirror = document.createElement('div')
    mirror.setAttribute('class', 'Tag__item__mirror')
    document.body.appendChild(mirror)
  }
  mirror.textContent = node.value
  node.style.width = getComputedStyle(mirror).width
}

const ContentEditor = (props) => {
  // console.log('ContentEditor -> props', props)

  const [value, setValue] = useState(props.value)

  const inputRef = useRef(null)

  useEffect(() => {
    const node = inputRef.current
    //todo handle this using css, causing shake in ui
    setValue(props.value)
    autoSize(node)
  }, [props.value])

  const handleBlur = () => {
    console.log('handleblur -> value')

    const node = inputRef.current
    // node.blur()
    if (value && value.trim()) {
      props.updateOption({ label: value, id: props.option.id })
    } else {
      props.updateOption({ label: props.option.label, id: props.option.id })
    }
  }

  const handleChange = (e) => {
    const node = inputRef.current
    autoSize(node)
    const value = e.target.value
    setValue(value)
  }

  const handleKeyDown = (event) => {
    event.stopPropagation()
    console.log('handleKeyDown -> handleKeyDown')
    if (event.key === 'Enter') {
      event.preventDefault()
      const node = inputRef.current
      node.blur()
    }
  }

  return (
    <input
      type="text"
      value={value}
      ref={inputRef}
      // onFocus={handleFocus}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      onChange={handleChange}
    />
  )
}

export default React.memo(ContentEditor, areEqual)
