import React, { useState } from 'react';

import withDeprecatedProp from '../../utils/hooks/depricatedPropsHoc';
import Button from '../Button/Button';
import Datepicker from '../Datepicker/Datepicker';
import TimePicker from '../Timepicker/TimePicker';

import './DateTimepicker.css';

interface IDateTimepicker {
  initialDate?: string
  formatType?: string
  startDate?: Date
  endDate?: Date
  onDone?: (data?: any) => void
  onCancel?: (data?: any) => void
  onCloseModal: () => void
  testId?: string
}

const DateTimepicker = (props: IDateTimepicker) => {
  const { initialDate, startDate, endDate, formatType, onDone, onCancel, onCloseModal, testId } = props
  const initDate = initialDate;
  const [dateVal, setDateVal] = useState(initialDate);
  const [timeVal, setTimeVal] = useState(initialDate);

  const doneHandler = () => {
    const today = new Date(initialDate)
    let updatedDate = new Date(dateVal)
    let updatedTime = initialDate === timeVal ? new Date(timeVal) : new Date(today.toDateString() + ' ' + timeVal)
    updatedDate.setHours(updatedTime.getHours(), updatedTime.getMinutes(), updatedTime.getSeconds())
    onDone(updatedDate.toISOString())
    if (onCloseModal) {
      onCloseModal();
    }
  };

  const cancelHandler = () => {
    onCancel(initDate);
    if (onCloseModal) {
      onCloseModal();
    }
  };

  const timeChangeHandler = (value: any) => {
    setTimeVal(value);
  }

  const dateChangeHandler = (value: any) => {
    setDateVal(value);
  }

  return (
    <div className="DateTimePicker" data-test-id={testId}>
      <Datepicker
        initialDate={initialDate}
        startDate={startDate}
        endDate={endDate}
        formatType={formatType}
        onChange={dateChangeHandler}
        viewType="datetime"
      />
      <TimePicker
        initialDate={initialDate ? initialDate : null}
        inputDisabled={false}
        placeholder="Select time"
        viewType="datetime"
        onChange={timeChangeHandler}
      />
      <div className="DateTimePicker__actions flex-right">
        <Button buttonType="primary" onClick={doneHandler}>Done</Button>
        <Button buttonType="tertiary" onClick={cancelHandler}>Cancel</Button>
      </div>
    </div>
  )
};

DateTimepicker.defaultProps = {
  testId: 'cs-datetimepicker'
}
export const DateTimePicker = withDeprecatedProp(DateTimepicker, { 'closeModal': 'onCloseModal' });
export default withDeprecatedProp(DateTimepicker, { 'closeModal': 'onCloseModal' }, { 'DateTimepicker': 'DateTimePicker' })
